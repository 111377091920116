import React, { useEffect, useState } from 'react';
import moment from 'moment'
import HelpIcon from '@mui/icons-material/Help';
import { AccessControl } from '../../components/Utils/AccessControl'
import CircleIcon from '@mui/icons-material/Circle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
  Backdrop,
  Box,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormLabel,
  Grid,
  Input,
  IconButton,
  Paper,
  Skeleton,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Typography,
  Tooltip
} from '@mui/material'
import { uploadFile } from '_services'
import { ordemServicoCobrancaAction, authActions, empenhoAction } from '../../_actions';
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useConfirm } from 'material-ui-confirm'
import { withRouter, } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import CustomButton from '../../components/CustomButton/CustomButton'
import { STATUS_SERVICO, history, MANUAIS_CATEGORIAS } from '../../utils'
import CardTableFinanceiro from './cardTableFinanceiro.component';
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const OrdemServicoFinanceiroEdit = ({ props, idOrdemServico, ordemServico }) => {

  const { isLoading } = props.ordemServicoCobranca;
  const confirm = useConfirm()
  const dispatch = useDispatch();
  const { classes } = props

  const { ordemServicoCobranca } = props.ordemServicoCobranca;

  const { match: { params } } = props;

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const [ready, setReady] = React.useState(false);

  const empenhos = useSelector((state) => state.ordemServico?.ordensServicosEmpenhos)

  const [openComprovante, setOpenComprovante] = useState(false)
  const [pagarCobranca, setPagarCobranca] = useState(false)
  const [pagarEmpenho, setPagarEmpenho] = useState(false)
  const [idCobranca, setIdCobranca] = useState(null)
  const [idEmpenho, setIdEmpenho] = useState(null)

  const [openNf, setOpenNf] = useState(false)
  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  useEffect(() => {
    if (pagarCobranca && idCobranca) {
      realizarPagamentoCobranca()
    }
  }, [props.ordemServicoCobranca.caminhoComprovantePagamento, pagarCobranca]);

  useEffect(() => {
    if (pagarEmpenho && idEmpenho) {
      realizarPagamentoEmpenho()
    }
  }, [props.empenho.caminhoNotaFiscal, pagarEmpenho]);

  const verifyObraHasEmpenho = () => {
    return empenhos?.some(ts => ts.idOrdemServico == parseInt(idOrdemServico) && ts.excluido != true);
  }

  useEffect(() => {

  }, [ready, isLoading])

  useEffect(() => {
    //limpar todos os dados ao carregar a tela
    dispatch(ordemServicoCobrancaAction.clear())
    dispatch(empenhoAction.clear())
    setPagarCobranca(false);
    setPagarEmpenho(false);
    setIdCobranca(null)
    setIdEmpenho(null)
  }, [])

  useEffect(() => {
    if (authActions.isEmpresa()) {
      if (props.authentication.user.usuariosEmpresas[0].idEmpresa != null && props.authentication.user.usuariosEmpresas[0].idEmpresa != '') {
        let filterModel = {
          IdOrdemServico: idOrdemServico,
          IdEmpresa: props.authentication.user.usuariosEmpresas[0].idEmpresa
        };

        dispatch(ordemServicoCobrancaAction.getOrdemServicoCobranca(filterModel))
      }
    }
  }, [])

  useEffect(() => {
    let filterModel = {
      IdOrdemServico: idOrdemServico
    };

    dispatch(ordemServicoCobrancaAction.getOrdemServicoCobranca(filterModel))

  }, [])

  const handleUploadComprovante = (e) => {
    setOpenComprovante(true)
    var file = e.target.files[0]
    if (file) {
      uploadFile(file)
        .then((response) => {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(ordemServicoCobrancaAction.onChangeProps('caminhoComprovantePagamento', event2))
          setOpenComprovante(false)
        })
        .catch((err) => {
          console.log(err)
          setOpenComprovante(false)
        })
    }
  }

  const handleUploadNotaFiscal = (e) => {
    setOpenNf(true)
    var file = e.target.files[0]
    if (file) {
      uploadFile(file)
        .then((response) => {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(empenhoAction.onChangeProps('caminhoNotaFiscal', event2))
          setOpenNf(false)
        })
        .catch((err) => {
          console.log(err)
          setOpenNf(false)
        })
    }
  }

  const isItemPaid = (id) => {
    if (id != undefined) {
      return ordemServicoCobranca?.some((n) => n.dataPagamento != null && n.id == id)
    }
  }

  const totalPagoFinanceiro = ordemServicoCobranca?.reduce((total, item) => {
    if (item.dataPagamento) {
      return total + item.valor;
    }
    return total;
  }, 0);

  const isEmpenhoPaid = () => {
    if (empenhos) {
      return empenhos?.some((n) => n.empenho?.dataPagamento != null && n.idOrdemServico == parseInt(idOrdemServico) && n.excluido != true)
    }
  }

  const totalEmAbertoFinanceiro = ordemServicoCobranca?.reduce((total, item) => {
    if (!item.dataPagamento) {
      return total + item.valor;
    }
    return total;
  }, 0);

  const totalCobrancasEmitidasFinanceiro = ordemServicoCobranca?.reduce((total, item) => {
    if (item) {
      return total + item.valor;
    }
    return total;
  }, 0);

  const handleOpenVisualiza = (link) => {
    window.open(link, '_blank');
  }

  const handleRealizaPagamento = (id) => {
    confirm({
      title: 'Você deseja realizar o pagamento desta Cobrança?',
      description: returnUploadComprovante(),
      disabled: props.ordemServicoCobranca.isLoading,
      confirmationText: props.ordemServicoCobranca.isLoading ? 'Realizando pagamento...' : 'Sim, pagar!',
      cancellationText: 'Não!',
    }).then(() => {
      setPagarCobranca(true)
      setIdCobranca(id)
    });
  };

  const realizarPagamentoCobranca = () => {
    if (idCobranca && idCobranca != 0) {
      dispatch(ordemServicoCobrancaAction.editOrdemServicoCobrancaPaga(idCobranca, idOrdemServico, {
        caminhoComprovantePagamento: props.ordemServicoCobranca.caminhoComprovantePagamento
      }));
    }
  }

  const realizarPagamentoEmpenho = () => {
    if (idEmpenho && idEmpenho != 0) {
      dispatch(empenhoAction.editEmpenhoRealizaPagamento(idEmpenho, idOrgao, idOrdemServico, {
        caminhoNotaFiscal: props.empenho.caminhoNotaFiscal
      }));
    }
  }

  function returnUploadComprovante() {
    return (
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xl={12} xs={12} align="left">
          <Backdrop className={classes.backdrop} open={openComprovante}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
            >
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
              <Grid item>
                <Typography variant="h4" color="textPrimary">
                  Enviando comprovante de pagamento...
                </Typography>
              </Grid>
            </Grid>
          </Backdrop>
          <FormLabel component="legend">Enviar Comprovante de Pagamento</FormLabel>
          <Input
            id="caminhoComprovantePagamento"
            className={classes.textField}
            label="Arquivo do comprovante"
            onChange={handleUploadComprovante}
            type="file"
            placeholder="Enviar Comprovante de Pagamento"
            formControl
            margin="dense"
          />
       
        </Grid>
      </Grid>
    )
  }

  const handleRealizaPagamentoEmpenho = (id) => {
    confirm({
      title: 'Você deseja marcar este empenho como pago?',
      description: returnUploadNF(),
      disabled: props.empenho.isLoading,
      confirmationText: props.empenho.isLoading ? 'Marcando como pago...' : 'Sim!',
      cancellationText: 'Não!'
    }).then(() => {
      setPagarEmpenho(true)
      setIdEmpenho(id)
    })
  }

  function returnUploadNF() {
    return (
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xl={12} xs={12} align="left">
          <Backdrop className={classes.backdrop} open={openNf}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
            >
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
              <Grid item>
                <Typography variant="h4" color="textPrimary">
                  Enviando nota fiscal...
                </Typography>
              </Grid>
            </Grid>
          </Backdrop>
          <FormLabel component="legend">Enviar Nota Fiscal</FormLabel>
          <Input
            id="caminhoNotaFiscal"
            className={classes.textField}
            label="Arquivo da NF"
            onChange={handleUploadNotaFiscal}
            type="file"
            placeholder="Enviar nota fiscal"
            formControl
            margin="dense"
          />
        </Grid>
      </Grid>
    )
  }


  function TitleText() {
    return (
      <Typography variant="h5">
        <b>{'Cobranças'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <TitleText />
      </Box>
    )
  }

  function TitleTextEmpenho() {
    return (
      <Typography variant="h5">
        <b>{'Empenho'}</b>
      </Typography>
    )
  }
  function SegHeaderEmpenho() {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <TitleTextEmpenho />
      </Box>
    )

  }

  return (
    <>
      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5, alignContent: 'center', marginTop: 3 }}>
        <Grid alignItems="center" container justifyContent="center" spacing={3} style={{ marginBottom: 15 }}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <CardTableFinanceiro
              valorEmAberto={`R$ ${ordemServicoCobranca?.length == 0 ?
                (ordemServico?.ordensServicosOrcamentos && ordemServico?.ordensServicosOrcamentos.length > 0) ?
                  (ordemServico?.ordensServicosOrcamentos[0]?.valor)?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) :
                  (totalEmAbertoFinanceiro != 0 && totalEmAbertoFinanceiro != undefined) ?
                    totalEmAbertoFinanceiro.toLocaleString('pt-br', { minimumFractionDigits: 2 }) :
                    '0,00' :
                (totalEmAbertoFinanceiro != 0 && totalEmAbertoFinanceiro != undefined) ? totalEmAbertoFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '0,00'}`}
              valorTotal={totalCobrancasEmitidasFinanceiro == 0 ? 'R$ 0,00' : `R$ ${totalCobrancasEmitidasFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
              valorPago={`R$ ${totalPagoFinanceiro ? totalPagoFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '0,00'}`}
              textValorTotal="Valor total das cobranças emitidas"
              title="RESUMO FINANCEIRO"
            />
          </Grid>
          {(authActions.isGestor() || authActions.isPrefeitura()) && (
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <CardTableFinanceiro
                valorEmAberto={!verifyObraHasEmpenho() ? 'R$ --,--' : !isEmpenhoPaid() ? `R$ ${totalCobrancasEmitidasFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : 'R$ 0,00'}
                valorTotal={!verifyObraHasEmpenho() ? 'R$ --,--' : `R$ ${totalCobrancasEmitidasFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                valorPago={!verifyObraHasEmpenho() ? 'R$ --,--' : isEmpenhoPaid() ? `R$ ${totalCobrancasEmitidasFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : 'R$ 0,00'}
                textValorTotal="Valor total do empenho"
                title="RESUMO EMPENHO"
              />
            </Grid>
          )}
        </Grid>
        <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CardHeader
            subheader="Relação de cobranças emitidas pela empresa"
            title={<SegHeader />}
          />
          <Grid item lg={1} md={1} xl={1} xs={12}>
            <IconButton
              aria-label="Ajuda"
              component="a"
              onClick={() =>
                handleOpenModalHelp()
              }
              size="small"
            >
              <Tooltip title={'Ajuda'}>
                <Tooltip>
                  <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                </Tooltip>
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
        {((authActions.isEmpresa() || authActions.isGestor()) && ((ordemServico.ultimoEvento == STATUS_SERVICO.VERIFICADO) || ordemServico.ultimoEvento == STATUS_SERVICO.AVALIADO || ordemServico.ultimoEvento == STATUS_SERVICO.CONCLUIDO)) &&
          <Grid item>
            <CustomButton
              size="small"
              variant="outlined"
              style={{ verticalAlign: 'middle' }}
              onClick={() => history.push(`/ordemServicoCobranca/`, idOrdemServico)}
              endIcon={<MonetizationOnIcon />}
            >
              Cadastrar nova cobrança
            </CustomButton>
          </Grid>
        }
        <Grid item lg={12} md={12} xl={12} xs={12} align="left">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                  <TableCell size="small">
                    <strong>DATA</strong>
                  </TableCell>
                  <TableCell size="small">
                    <strong>EMPRESA</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>NOTA FISCAL</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>COMPROVANTE PAGAMENTO</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>VALOR</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>STATUS</strong>
                  </TableCell>
                  <TableCell size="small" align="center">

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ordemServicoCobranca && undefined !== ordemServicoCobranca && ordemServicoCobranca.length
                  ? ordemServicoCobranca.map((n) => {
                    return (
                      <TableRow hover key={n.id}>
                        <TableCell component="th" scope="row">
                          {n.dataCobranca ? moment(n.dataCobranca).format("DD/MM/YYYY HH:mm") : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {n.caminhoNota ?
                            <CustomButton
                              variant="outlined"
                              size="small"
                              style={{ justifyContent: 'center' }}
                              onClick={() => handleOpenVisualiza(n.caminhoNota)}
                            >
                              Visualizar
                            </CustomButton>
                            :
                            null}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {n.caminhoComprovantePagamento ?
                            <CustomButton
                              variant="outlined"
                              size="small"
                              style={{ justifyContent: 'center' }}
                              onClick={() => handleOpenVisualiza(n.caminhoComprovantePagamento)}
                            >
                              Visualizar
                            </CustomButton>
                            :
                            '-'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {n.valor ? 'R$ ' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Tooltip
                            title={n.dataPagamento != null ? `Pago em ${moment(n.dataPagamento).format("DD/MM/YYYY HH:mm")}` : false}
                          >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> <CircleIcon sx={{ color: n.dataPagamento == null ? '#cf0d0d' : '#0d6e0d', fontSize: 12, marginRight: 1 }} /> <span>{n.dataPagamento == null ? 'EM ABERTO' : `PAGO EM ${moment(n.dataPagamento).format("DD/MM/YYYY HH:mm")}`}</span></div>
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <AccessControl
                            rule={'ordensServicosCobrancas.pay'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <Checkbox
                                onClick={() => handleRealizaPagamento(n.id)}
                                color="primary"
                                size="small"
                                disabled={isItemPaid(n.id)}
                                checked={isItemPaid(n.id)}
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow >
                    )
                  })
                  :
                  (
                    !isLoading ?
                      <TableRow>
                        <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                      </TableRow>
                      :
                      Array.apply(null, { length: 5 }).map((e, i) => (
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      ))
                  )
                }
              </TableBody>
            </Table>

          </TableContainer>
        </Grid>
        {(authActions.isGestor() || authActions.isPrefeitura()) && (
          <>
            <CardHeader
              subheader="Informações do empenho desta Ordem de Serviço"
              title={<SegHeaderEmpenho />}
            />
            <Grid item lg={12} md={12} xl={12} xs={12} align="left">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                      <TableCell size="small">
                        <strong>DATA</strong>
                      </TableCell>
                      <TableCell size="small" align="center">
                        <strong>NOTA FISCAL</strong>
                      </TableCell>
                      <TableCell size="small" align="center">
                        <strong>ARQUIVO</strong>
                      </TableCell>
                      <TableCell size="small" align="center">
                        <strong>VALOR</strong>
                      </TableCell>
                      <TableCell size="small" align="center">
                        <strong>STATUS</strong>
                      </TableCell>
                      <TableCell size="small" align="center">

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {empenhos && undefined !== empenhos && empenhos.length
                      ? empenhos.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.empenho.dataCadastro ? moment(n.empenho.dataCadastro).format("DD/MM/YYYY HH:mm") : ''}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              {n.empenho.caminhoNotaFiscal ?
                                <CustomButton
                                  variant="outlined"
                                  size="small"
                                  style={{ justifyContent: 'center' }}
                                  onClick={() => handleOpenVisualiza(n.empenho.caminhoNotaFiscal)}
                                >
                                  Visualizar
                                </CustomButton>
                                :
                                '-'}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              {n.empenho.caminhoArquivo ?
                                <CustomButton
                                  variant="outlined"
                                  size="small"
                                  style={{ justifyContent: 'center' }}
                                  onClick={() => handleOpenVisualiza(n.empenho.caminhoArquivo)}
                                >
                                  Visualizar
                                </CustomButton>
                                :
                                '-'}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              R$ {totalCobrancasEmitidasFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              <Tooltip
                                title={n.empenho.dataPagamento != null ? `Pago em ${moment(n.empenho.dataPagamento).format("DD/MM/YYYY HH:mm")}` : false}
                              >
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> <CircleIcon sx={{ color: n.empenho.dataPagamento == null ? '#cf0d0d' : '#0d6e0d', fontSize: 12, marginRight: 1 }} /> <span>{n.empenho.dataPagamento == null ? 'EM ABERTO' : `PAGO EM ${moment(n.empenho.dataPagamento).format("DD/MM/YYYY HH:mm")}`}</span></div>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                              <AccessControl
                                rule={'empenhos.pay'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <Tooltip title={'Receber pagamento'}>
                                    <Tooltip >
                                      <Checkbox
                                        onClick={() => handleRealizaPagamentoEmpenho(n.empenho.id)}
                                        color="primary"
                                        size="small"
                                        disabled={isEmpenhoPaid(n.empenho.id)}
                                        checked={isEmpenhoPaid(n.empenho.id)}
                                      />
                                    </Tooltip>
                                  </Tooltip>
                                )}
                              />
                            </TableCell>
                          </TableRow >
                        )
                      })
                      :
                      (
                        !isLoading ?
                          <TableRow>
                            <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                          :
                          Array.apply(null, { length: 5 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
        {
          (modalHelp ?
            <HelpModal
              toggle={toggleModalHelp}
              modal={modalHelp}
              props={props}
              tela={MANUAIS_CATEGORIAS.ABA_ORDEM_SERVICO_FINANCEIRO}
            />
            :
            null
          )
        }
      </Grid>
    </>
  );
};

OrdemServicoFinanceiroEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedOrdemServicoFinanceiroEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdemServicoFinanceiroEdit))
)
export { connectedOrdemServicoFinanceiroEditPage as OrdemServicoFinanceiroEdit }

export default OrdemServicoFinanceiroEdit;
