import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { relatorioAction } from '../../_actions'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment'
import HelpIcon from '@mui/icons-material/Help';
import { toast } from 'react-toastify'
import TitleContentInformation from '../TitleContentInformation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { stringUtils, MANUAIS_CATEGORIAS } from '../../utils'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'
import { crudService } from '../../_services'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const RelatorioLogsSistema = (props) => {

  const dispatch = useDispatch()
  const isFirstRender = useRef(true);
  const { classes, className, ...rest } = props
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [tabela, setTabela] = useState('');
  const [inputValueTabela, setInputValueTabela] = useState('');
  const [dataHoraInicio, setDataHoraInicio] = useState('');
  const [dataHoraFim, setDataHoraFim] = useState('');
  const [campo, setCampo] = useState('');
  const [usuario, setUsuario] = useState('');
  const [options, setOptions] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [ready, setReady] = React.useState(false);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  let filterModel = {
    Tabela: tabela?.value ?? '',
    DataIntervaloInicio: dataHoraInicio,
    DataIntervaloFim: dataHoraFim,
    Campo: campo,
    Usuario: usuario,
    Page: page,
    Limit: limit
  };

  useEffect(() => {
    dispatch(relatorioAction.clear());

    const getTables = () => {
      let apiEndpoint = 'relatorios/select/tabelas'
      crudService
        .get(apiEndpoint)
        .then((response) => {
          if (response.data) {
            response.data.unshift({ value: '', text: ' SELECIONE ...' })
            setOptions(response.data)
          }
        })
    }

    getTables();
  }, []);

  useEffect(() => {
    if (tabela) {
      handleChangeAutoComplete(tabela)
    }
  }, [tabela])

  const handleChangeAutoComplete = (nameArray) => {
    if (nameArray != null) {

      let selected = options.filter(
        (item) => item.value == nameArray.value
      )

      setTabela(selected[0]);

    } else {

    }
  }

  const handlePrint = () => {
    const queryParams = new URLSearchParams(filterModel).toString();
    if (queryParams) {
      window.open(`/relatorioLogsSistemaPrint?${queryParams}`, '_blank');
    }
  }

  const handleExportExcel = () => {

    const modifiedFilterModel = {
      ...filterModel,
      Limit: 9999999
    };

    dispatch(relatorioAction.getRelatorioLogsSistemaArquivo(modifiedFilterModel));
  }

  const handleSubmit = (event) => {
    if (dataHoraFim >= dataHoraInicio) {
      dispatch(relatorioAction.getRelatorioLogsSistema(filterModel, true));
    } else {
      toast.error('Data do intervalo de início não pode ser maior que a data de intervalo de fim')
    }
  }

  useEffect(() => {
    // se é a primeira renderização, não faz a chamada
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    dispatch(relatorioAction.getRelatorioLogsSistema(filterModel, true));
  }, [page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.relatorio.isLoading])

  return (
    <Page className={classes.root} title="Relatório - Logs">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorio"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                      <TitleContentInformation
                        subTitle={'RELATÓRIO'}
                        title={'Relatório de Logs do Sistema'}
                      />
                      <Grid item lg={1} md={1} xl={1} xs={12}>
                        <IconButton
                          aria-label="Ajuda"
                          component="a"
                          onClick={() =>
                            handleOpenModalHelp()
                          }
                          size="small"
                        >
                          <Tooltip title={'Ajuda'}>
                            <Tooltip>
                              <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                            </Tooltip>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ marginTop: 16 }}>
                        {options &&
                          <Autocomplete
                            value={tabela}
                            onChange={(event, newValue) => {
                              setTabela(newValue);
                            }}
                            inputValue={inputValueTabela}
                            onInputChange={(event, newInputValueTabela) => {
                              setInputValueTabela(newInputValueTabela);
                            }}
                            id="controllable-states-demo"
                            options={options}
                            margin="normal"
                            variant="outlined"
                            defaultValue={tabela}
                            getOptionLabel={(o) => (o ? o.text : '')}
                            renderInput={(params) => <TextField {...params} label="Tabela... " fullWidth />}
                            fullWidth
                          />
                        }
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="usuario"
                          label="Usuário"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={(e) => setUsuario(e.target.value)}
                          value={usuario || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="usuario"
                          label="Campo"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={(e) => setCampo(e.target.value)}
                          value={campo || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data Intervalo Início"
                          margin="normal"
                          onChange={(e) => setDataHoraInicio(e.target.value)}
                          value={dataHoraInicio || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data Intervalo Fim"
                          margin="normal"
                          onChange={(e) => setDataHoraFim(e.target.value)}
                          value={dataHoraFim || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <CustomButton
                            type="submit"
                            variant="contained"
                            dark={true}
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFilePdf} style={{ verticalAlign: 'middle' }} />}
                          >
                            Gerar PDF
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="button"
                            onClick={() => handleExportExcel()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
                          >
                            Exportar para Excel
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>DATA</strong></TableCell>
                        <TableCell align="right"><strong>CÓDIGO</strong></TableCell>
                        <TableCell><strong>TABELA</strong></TableCell>
                        <TableCell><strong>CAMPO</strong></TableCell>
                        <TableCell><strong>USUÁRIO</strong></TableCell>
                        <TableCell><strong>VALOR ANTIGO</strong></TableCell>
                        <TableCell><strong>VALOR NOVO</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {relatorio && undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.dataHora ? moment(n.dataHora).format("DD/MM/yyyy HH:mm") : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                {n.chave ? n.chave : ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.tabela ? n.tabela.toUpperCase() : ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.campo ? n.campo.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.usuario ? n.usuario.nome.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.valorAntigo ? n.valorAntigo : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.valorNovo ? n.valorNovo : '-'}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={totalRegistros}
                    page={page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeLimit}
                  />
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.RELATORIO_LOGS_SISTEMA}
          />
          :
          null
        )
      }
    </Page >
  )
}
RelatorioLogsSistema.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioLogsSistemaPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(RelatorioLogsSistema))
)
export { connectedRelatorioLogsSistemaPage as RelatorioLogsSistema }
