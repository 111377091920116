import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouteWithLayout } from './components'
import { PrivateRouteWithLayout } from './components'
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from './layouts'

import {
  Manual as ManualView,
  ManualEdit as ManualEditView,
  Error404 as Error404View,
  EmailVerification as EmailVerificationView,
  Empenho as EmpenhoView,
  EmpenhoEdit as EmpenhoEditView,
  Empresa as EmpresaView,
  EmpresaEdit as EmpresaEditView,
  EmpresaUsuarioEdit as EmpresaUsuarioEditView,
  Login as LoginView,
  Marca as MarcaView,
  MarcaEdit as MarcaEditView,
  Notificacao as NotificacaoView,
  NotificacaoEdit as NotificacaoEditView,
  OrdemServico as OrdemServicoView,
  OrdemServicoCobranca as OrdemServicoCobrancaView,
  OrdemServicoCobrancaEdit as OrdemServicoCobrancaEditView,
  OrdemServicoEdit as OrdemServicoEditView,
  OrdensServicoPublico as OrdensServicoPublicoView,
  OrdemServicoOrcamento as OrdemServicoOrcamentoView,
  OrdemServicoOrcamentoEdit as OrdemServicoOrcamentoEditView,
  OrdemServicoAguardandoOrcamento as OrdemServicoAguardandoOrcamentoView,
  Orgao as OrgaoView,
  OrgaoEdit as OrgaoEditView,
  OrgaoUsuarioEdit as OrgaoUsuarioEditView,
  Parametro as ParametroView,
  ParametroEdit as ParametroEditView,
  PasswordReset as PasswordResetView,
  PasswordResetSuccess as PasswordResetSuccessView,
  PoliticaPrivacidade as PoliticaPrivacidadeView,
  RelatorioLogsSistema as RelatorioLogsSistemaView,
  RelatorioLogsSistemaPrint as RelatorioLogsSistemaPrintView,
  RelatorioEmpresaPorOrgao as RelatorioEmpresaPorOrgaoView,
  RelatorioEmpresaPorOrgaoPrint as RelatorioEmpresaPorOrgaoPrintView,
  RelatorioServicoRealizado as RelatorioServicoRealizadoView,
  RelatorioServicoRealizadoPrint as RelatorioServicoRealizadoPrintView,
  RelatorioOrcamentosOrdemServicoPrint as RelatorioOrcamentosOrdemServicoPrintView,
  RelatorioOcorrenciaOrdemServico as RelatorioOcorrenciaOrdemServicoView,
  RelatorioOcorrenciaOrdemServicoPrint as RelatorioOcorrenciaOrdemServicoPrintView,
  SubOrgao as SubOrgaoView,
  SubOrgaoEdit as SubOrgaoEditView,
  Dashboard as DashboardView,
  Usuario as UsuarioView,
  UsuarioEdit as UsuarioEditView,
  Veiculo as VeiculoView,
  VeiculoEdit as VeiculoEditView,
  RelatorioLogsSistema
} from './views'

const Routes = () => {
  return (
    <Switch>
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/"
      />
       <PrivateRouteWithLayout
        component={ManualView}
        exact
        layout={MainLayout}
        path="/manuais"
      />
       <PrivateRouteWithLayout
        component={ManualEditView}
        exact
        layout={MainLayout}
        path="/manual"
      />
      <PrivateRouteWithLayout
        component={ManualEditView}
        exact
        layout={MainLayout}
        path="/manual/:id"
      />
      <Route
        component={EmpresaUsuarioEditView}
        exact
        layout={MainLayout}
        path="/empresaCadastro"
      />
      <PrivateRouteWithLayout
        component={EmpenhoView}
        exact
        layout={MainLayout}
        path="/empenhos"
      />
      <PrivateRouteWithLayout
        component={EmpenhoEditView}
        exact
        layout={MainLayout}
        path="/empenho"
      />
      <PrivateRouteWithLayout
        component={EmpenhoEditView}
        exact
        layout={MainLayout}
        path="/empenho/:id"
      />
      <PrivateRouteWithLayout
        component={MarcaView}
        exact
        layout={MainLayout}
        path="/marcas"
      />
      <PrivateRouteWithLayout
        component={MarcaEditView}
        exact
        layout={MainLayout}
        path="/marca"
      />
      <PrivateRouteWithLayout
        component={MarcaEditView}
        exact
        layout={MainLayout}
        path="/marca/:id"
      />
      <Route
        component={OrgaoUsuarioEditView}
        exact
        layout={MainLayout}
        path="/orgaoCadastro"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoCobrancaView}
        exact
        layout={MainLayout}
        path="/ordensServicosCobrancas"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoCobrancaEditView}
        exact
        layout={MainLayout}
        path="/ordemServicoCobranca/:id"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoCobrancaEditView}
        exact
        layout={MainLayout}
        path="/ordemServicoCobranca"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoAguardandoOrcamentoView}
        exact
        layout={MainLayout}
        path="/ordensServicoAguardandoOrcamento"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoOrcamentoView}
        exact
        layout={MainLayout}
        path="/ordensServicoOrcamentos"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoOrcamentoEditView}
        exact
        layout={MainLayout}
        path="/ordensServicoOrcamento"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoOrcamentoEditView}
        exact
        layout={MainLayout}
        path="/ordensServicoOrcamento/:id"
      />
      <PrivateRouteWithLayout
        component={SubOrgaoView}
        exact
        layout={MainLayout}
        path="/subOrgaos"
      />
      <PrivateRouteWithLayout
        component={SubOrgaoEditView}
        exact
        layout={MainLayout}
        path="/subOrgao"
      />
      <PrivateRouteWithLayout
        component={SubOrgaoEditView}
        exact
        layout={MainLayout}
        path="/subOrgao/:id"
      />
      <Route
        component={PoliticaPrivacidadeView}
        exact
        layout={MainLayout}
        path="/politica-de-privacidade"
      />
      <PrivateRouteWithLayout
        component={RelatorioLogsSistemaView}
        exact
        layout={MainLayout}
        path="/relatorioLogsSistema"
      />
      <PrivateRouteWithLayout
        component={RelatorioLogsSistemaPrintView}
        exact
        layout={MainLayout}
        path="/relatorioLogsSistemaPrint"
      />
      <PrivateRouteWithLayout
        component={RelatorioEmpresaPorOrgaoView}
        exact
        layout={MainLayout}
        path="/relatorioEmpresasPorOrgao"
      />
      <PrivateRouteWithLayout
        component={RelatorioEmpresaPorOrgaoPrintView}
        exact
        layout={MainLayout}
        path="/relatorioEmpresaPorOrgaoPrint"
      />
      <PrivateRouteWithLayout
        component={RelatorioServicoRealizadoView}
        exact
        layout={MainLayout}
        path="/relatorioServicosRealizados"
      />
      <PrivateRouteWithLayout
        component={RelatorioServicoRealizadoPrintView}
        exact
        layout={MainLayout}
        path="/relatorioServicosRealizadosPrint"
      />
      <PrivateRouteWithLayout
        component={RelatorioOrcamentosOrdemServicoPrintView}
        exact
        layout={MainLayout}
        path="/relatorioOrcamentosOrdemServico"
      />
      <PrivateRouteWithLayout
        component={RelatorioOcorrenciaOrdemServicoView}
        exact
        layout={MainLayout}
        path="/relatorioOcorrenciasOrdemServico"
      />
      <PrivateRouteWithLayout
        component={RelatorioOcorrenciaOrdemServicoPrintView}
        exact
        layout={MainLayout}
        path="/relatorioOcorrenciasOrdemServicoPrint"
      />
      <Route
        component={OrdensServicoPublicoView}
        exact
        layout={MainLayout}
        path="/ordemServico-publico"
      />
      <PrivateRouteWithLayout
        component={NotificacaoView}
        exact
        layout={MainLayout}
        path="/notificacoes"
      />
      <PrivateRouteWithLayout
        component={NotificacaoEditView}
        exact
        layout={MainLayout}
        path="/notificacao"
      />
      <PrivateRouteWithLayout
        component={NotificacaoEditView}
        exact
        layout={MainLayout}
        path="/notificacao/:id"
      />
      <PrivateRouteWithLayout
        component={UsuarioView}
        exact
        layout={MainLayout}
        path="/usuarios"
      />
      <PrivateRouteWithLayout
        component={UsuarioEditView}
        exact
        layout={MainLayout}
        path="/usuario"
      />
      <PrivateRouteWithLayout
        component={UsuarioEditView}
        exact
        layout={MainLayout}
        path="/usuario/:id"
      />
      <PrivateRouteWithLayout
        component={EmpresaView}
        exact
        layout={MainLayout}
        path="/empresas"
      />
      <PrivateRouteWithLayout
        component={EmpresaEditView}
        exact
        layout={MainLayout}
        path="/empresa"
      />
      <PrivateRouteWithLayout
        component={EmpresaEditView}
        exact
        layout={MainLayout}
        path="/empresa/:id"
      />
      <PrivateRouteWithLayout
        component={OrgaoView}
        exact
        layout={MainLayout}
        path="/orgaos"
      />
      <PrivateRouteWithLayout
        component={OrgaoEditView}
        exact
        layout={MainLayout}
        path="/orgao"
      />
      <PrivateRouteWithLayout
        component={OrgaoEditView}
        exact
        layout={MainLayout}
        path="/orgao/:id"
      />
      <PrivateRouteWithLayout
        component={ParametroView}
        exact
        layout={MainLayout}
        path="/parametros"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainLayout}
        path="/parametro"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainLayout}
        path="/parametro/:id"
      />
      <PrivateRouteWithLayout
        component={VeiculoView}
        exact
        layout={MainLayout}
        path="/veiculos"
      />
      <PrivateRouteWithLayout
        component={VeiculoEditView}
        exact
        layout={MainLayout}
        path="/veiculo"
      />
      <PrivateRouteWithLayout
        component={VeiculoEditView}
        exact
        layout={MainLayout}
        path="/veiculo/:id"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoView}
        exact
        layout={MainLayout}
        path="/ordensServico"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoEditView}
        exact
        layout={MainLayout}
        path="/ordemServico"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoEditView}
        exact
        layout={MainLayout}
        path="/ordemServico/:id"
      />
      <Route component={LoginView} exact path="/login" />
      <Route
        component={EmailVerificationView}
        exact
        path="/email-verification"
      />
      <Route
        component={PasswordResetView}
        exact
        path="/password-reset"
      />
      <Route
        component={PasswordResetSuccessView}
        exact
        path="/password-reset-success"
      />
      <RouteWithLayout
        component={Error404View}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
