import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { marcaAction } from '../../_actions'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import {
  Grid,
  Input,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material'
import { withRouter } from 'react-router-dom'
import { MANUAIS_CATEGORIAS } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  }
})

const Marca = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { marca } = props.marca
  const { totalRegistros } = props.marca
  const { isLoading } = props.marca

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [ready, setReady] = React.useState(false);

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction
      };

      dispatch(marcaAction.getMarca(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction]);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, props.marca.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Marcas">
      <AccessControl
        rule={'marcas.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== marca && marca.length > 0 ? (
              <TitleContent
                rule={'marcas.create'}
                length={marca.length}
                subTitle={'GERÊNCIA DE MARCA'}
                title={'Lista de Marcas'}
                href={'/marca'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_MARCA}
              />
            ) : (
              <TitleContent
                rule={'marcas.create'}
                length={0}
                subTitle={'GERÊNCIA DE MARCA'}
                title={'Lista de Marcas'}
                href={'/marca'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_MARCA}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== marca && marca.length
                    ? marca.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.nome ? n.nome.toUpperCase() : ''}
                          </TableCell>
                          {/*<TableCell align="right">*/}
                          {/*  <AccessControl*/}
                          {/*    rule={'marcas.edit'} //permissão necessária para acessar conteúdo*/}
                          {/*    yes={() => (*/}
                          {/*      <IconButton*/}
                          {/*        aria-label="Edit"*/}
                          {/*        component="a"*/}
                          {/*        onClick={() =>*/}
                          {/*          history.push(`/marca/${n.id}`)*/}
                          {/*        }*/}
                          {/*        size="small"*/}
                          {/*      >*/}
                          {/*        <Tooltip title={'Editar'}>*/}
                          {/*          <Tooltip>*/}
                          {/*            <EditIcon />*/}
                          {/*          </Tooltip>*/}
                          {/*        </Tooltip>*/}
                          {/*      </IconButton>*/}
                          {/*    )}*/}
                          {/*  />*/}
                          {/*  <AccessControl*/}
                          {/*    rule={'marcas.delete'} //permissão necessária para acessar conteúdo*/}
                          {/*    yes={() => (*/}
                          {/*      <IconButton*/}
                          {/*        aria-label="Delete"*/}
                          {/*        onClick={() => handleClick(n.id)}*/}
                          {/*        size="small"*/}
                          {/*      >*/}
                          {/*        <Tooltip title="Excluir">*/}
                          {/*          <DeleteIcon />*/}
                          {/*        </Tooltip>*/}
                          {/*      </IconButton>*/}
                          {/*    )}*/}
                          {/*  />*/}
                          {/*</TableCell>*/}
                        </TableRow>
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            {/*<TableCell component="th" scope="row">*/}
                            {/*  <Skeleton animation="wave" />*/}
                            {/*</TableCell>*/}
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Marca.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedMarcaPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Marca))
)
export { connectedMarcaPage as Marca }
