import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import clsx from 'clsx'
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles'
import moment from 'moment'
import Typography from '@mui/material/Typography';
import {
  Backdrop,
  Card,
  Checkbox,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormLabel,
  Paper,
  Grid,
  IconButton,
  Input,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { toast } from 'react-toastify'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'
import { uploadFile } from '_services'
import {
  empenhoAction,
  ordemServicoAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import HelpIcon from '@mui/icons-material/Help';
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import { Page } from 'components'
import SendIcon from '@mui/icons-material/Send';
import CustomButton from '../../components/CustomButton/CustomButton'
import { EmpenhoSummaryCard } from './empenhoSummaryCard.component';
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const TableCellStyled = styled(TableCell)(() => ({
  backgroundColor: '#f2f2f2',
  paddingTop: 6,
  paddingBottom: 6,
  textAlign: 'center',
}));

const TableCellStyled2 = styled(TableCell)(() => ({
  backgroundColor: '#f2f2f2',
  cursor: 'pointer'
}));

const DivStyled = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

const EmpenhoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, orgao, className, ...rest } = props
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(undefined)
  const [fileNF, setFileNF] = useState(undefined)
  const [idEmpresas, setIdEmpresas] = useState([])
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [order] = useState('data');
  const [direction] = useState('desc');
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const [ordensServicosEmpenhos, setOrdemServicosEmpenhos] = useState(props.empenho.ordensServicosEmpenhos);
  const ordensServicosPorEmpresa = {};
  const [expandedEmpresaId, setExpandedEmpresaId] = useState(null);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const location = useLocation();
  const idEmpenho = location.state;

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const handleToggle = (empresaId) => {
    setExpandedEmpresaId(empresaId == expandedEmpresaId ? null : empresaId);
  };

  const ordemServico = useSelector((state) => state.ordemServico.ordemServico)

  useEffect(() => {

    if (!idEmpenho) {
      dispatch(empenhoAction.clear());

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: idOrgao ? idOrgao : 0
      };

      dispatch(ordemServicoAction.getOrdemServicoSemEmpenho(filterModel))
    } else {
      // se tiver id do empenho, pega os dados dele
      dispatch(empenhoAction.getEmpenhoById(idEmpenho))
    }
  }, [idOrgao]);

  useEffect(() => {
    if (idOrgao == '') {
      toast.error('Selecione um órgão')
      history.push('/empenhos')
    }
  }, [idOrgao])

  const returnNameArquivoEmpenho = () => {
    if (idEmpenho && props.empenho?.caminhoArquivo && props.empenho?.caminhoArquivo != undefined) {
      const pathArray = props.empenho?.caminhoArquivo.split('/');
      const nomeArquivo = pathArray[pathArray.length - 1];
      return nomeArquivo;
    }
  }

  const returnNameArquivoNF = () => {
    if (idEmpenho && props.empenho?.caminhoNotaFiscal && props.empenho?.caminhoNotaFiscal != undefined) {
      const pathArray = props.empenho?.caminhoNotaFiscal.split('/');
      const nomeArquivo = pathArray[pathArray.length - 1];
      return nomeArquivo;
    }
  }

  function calcValorTotalEmpenho() {
    const valorEmpenho = ordensServicosEmpenhos?.reduce((subtotal, orc) => {
      return subtotal + (orc.valorOrdemServico || 0);
    }, 0);

    return valorEmpenho;
  };

  const handleAddListaOrdemServicosEnviarEmpenho = (idOrdemServico, valorOrcamento, idEmpresaSelected) => {
    // Verificar se a empresa já está na lista
    const empresaAlreadyAdded = idEmpresas.some(ts => ts.idEmpresa === parseInt(idEmpresaSelected));

    if (empresaAlreadyAdded) {
      // Se já estiver na lista, não faz nada
      // setIdEmpresas(prevIdEmpresas => prevIdEmpresas.filter(ts => ts.idEmpresa !== parseInt(idEmpresaSelected)));
    } else {
      // Caso contrário, adicione
      setIdEmpresas(prevIdEmpresas => [...prevIdEmpresas, { idEmpresa: parseInt(idEmpresaSelected) }]);
    }

    if (idOrdemServico) {
      if (ordensServicosEmpenhos.some(ts => ts.idOrdemServico === parseInt(idOrdemServico))) {
        setOrdemServicosEmpenhos(prevOrdensServicosEmpenhos => prevOrdensServicosEmpenhos.filter(ts => ts.idOrdemServico !== parseInt(idOrdemServico)));
      } else {
        setOrdemServicosEmpenhos(prevOrdensServicosEmpenhos => [...prevOrdensServicosEmpenhos, { idOrdemServico: parseInt(idOrdemServico), valorOrdemServico: valorOrcamento }]);
      }
    }
  }

  const isContainedListaOrdemServicosEnviarEmpenho = (idOrdemServico) => {
    if (idOrdemServico != undefined) {
      return ordensServicosEmpenhos.some(ts => ts.idOrdemServico == parseInt(idOrdemServico));
    }
    return false;
  }

  const handleChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  const handleChangeFileNotaFiscal = (e) => {
    setFileNF(e.target.files[0])
  }

  useEffect(() => {
    if (file && file != undefined && file != null) {
      setOpen(true)
      upload('caminhoArquivo', file)
    }
  }, [file])

  useEffect(() => {
    if (fileNF && fileNF != undefined && fileNF != null) {
      setOpen(true)
      upload('caminhoNotaFiscal', fileNF)
    }
  }, [fileNF])

  for (const o of ordemServico) {
    if (o.ordensServicosOrcamentos && o.ordensServicosOrcamentos.length > 0) {
      const empresaId = o.ordensServicosOrcamentos[0]?.idEmpresa;
      if (!ordensServicosPorEmpresa[empresaId]) {
        ordensServicosPorEmpresa[empresaId] = [];
      }
      ordensServicosPorEmpresa[empresaId].push(o);
    }
  }

  const upload = (prop, fileUpload) => {
    uploadFile(fileUpload)
      .then((response) => {
        setTimeout(function () {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(empenhoAction.onChangeProps(prop, event2))
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  //verifica se todas as empresas selecionadas são iguais
  function areObjectsEqual(objArray) {
    if (objArray.length === 1) {
      return true;
    }

    const empresaIds = objArray.map(obj => obj.idEmpresa);
    return empresaIds.every(id => id === empresaIds[0]);
  }

  const handleOpenArquivo = (link) => {
    window.open(link, '_blank');
  }

  const handleSubmit = () => {
    if (areObjectsEqual(idEmpresas)) {
      if (ordensServicosEmpenhos && ordensServicosEmpenhos.length > 0) {
        let payload = {
          caminhoArquivo: props.empenho.caminhoArquivo,
          caminhoNotaFiscal: props.empenho.caminhoNotaFiscal,
          idOrgao: idOrgao,
          ordensServicosEmpenhos: ordensServicosEmpenhos,
          valor: calcValorTotalEmpenho(),
          idEmpresa: idEmpresas && idEmpresas[0].idEmpresa
        }
        dispatch(empenhoAction.createEmpenho(payload))

      } else {
        toast.error('É necessário selecionar pelo menos uma ordem de serviço para prosseguir')
      }
    } else {
      toast.error('Somente é permitido criar um empenho com ordens de serviço de uma mesma empresa')
    }
  }

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const handleEdit = () => {
    if (idEmpenho) {
      let payload = {
        id: idEmpenho,
        caminhoArquivo: props.empenho.caminhoArquivo,
        caminhoNotaFiscal: props.empenho.caminhoNotaFiscal,
        idOrgao: idOrgao,
        ordensServicosEmpenhos: props.empenho.ordensServicosEmpenhos,
        idEmpresa: props.empenho.idEmpresa
      }

      dispatch(empenhoAction.editEmpenhoInfo(idEmpenho, payload))

    }
  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Empenho'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Empenho'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Empenhos - Editar Empenho'
          : 'Gerência de Empenhos - Novo Empenho'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          {(params.id || props.empenho.id) &&
            <EmpenhoSummaryCard idEmpenho={parseInt(params.id)} />
          }
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formEmpenho"
                onSubmit={(event) => !idEmpenho ? handleSubmit(event) : handleEdit(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Empenhos"
                    title={<SegHeader />}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                {!idEmpenho &&
                  <>
                    <Divider />
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={7}>
                                  <Typography variant="h6" align="center" style={{ marginBottom: 3 }}>
                                    <b>Selecione a(s) ordem(s) de serviço para a(s) qual(is) você deseja enviar um empenho</b>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell><strong>Código</strong></TableCell>
                                <TableCell><strong>Título</strong></TableCell>
                                <TableCell><strong>Descritivo</strong></TableCell>
                                <TableCell><strong>Órgão</strong></TableCell>
                                <TableCell><strong>Valor Orçamento Aprovado</strong></TableCell>
                                <TableCell><strong>Data de Cadastro</strong></TableCell>
                                <TableCell align="right"><strong>Ações</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(ordensServicosPorEmpresa).length > 0 ? (
                                Object.keys(ordensServicosPorEmpresa).map((empresaId) => {
                                  const ordensServicosEmpresa = ordensServicosPorEmpresa[empresaId];
                                  const empresaNome = ordensServicosEmpresa[0]?.ordensServicosOrcamentos[0]?.empresa?.razaoSocial?.toUpperCase();
                                  const isExpanded = expandedEmpresaId == empresaId;

                                  return (
                                    <React.Fragment key={empresaId}>
                                      <TableRow>
                                        <TableCellStyled colSpan={6}>
                                          <DivStyled>
                                            <WorkOutlineRoundedIcon sx={{ marginRight: 2 }} />
                                            <span>{empresaNome}</span>
                                          </DivStyled>
                                        </TableCellStyled>
                                        <Tooltip title="Expandir ordens de serviço da empresa">
                                          <TableCellStyled2 align="right">
                                            <ExpandMoreIcon onClick={() => handleToggle(empresaId)} />
                                          </TableCellStyled2>
                                        </Tooltip>
                                      </TableRow>
                                      {isExpanded &&
                                        ordensServicosEmpresa?.map((ordemServico) => (
                                          <TableRow hover key={ordemServico.id}>
                                            <TableCell>{ordemServico.id}</TableCell>
                                            <TableCell>{ordemServico.titulo ? ordemServico.titulo.toUpperCase() : ''}</TableCell>
                                            <TableCell>{ordemServico.descritivo ? ordemServico.descritivo.toUpperCase() : ''}</TableCell>
                                            <TableCell>{ordemServico.orgao.nome ? ordemServico.orgao.nome.toUpperCase() : ''}</TableCell>
                                            <TableCell>{ordemServico.ordensServicosOrcamentos ? ordemServico.ordensServicosOrcamentos[0]?.valor?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}</TableCell>
                                            <TableCell>{ordemServico.dataCadastro ? moment(ordemServico.dataCadastro).format("DD/MM/YYYY HH:mm") : ''}</TableCell>
                                            <TableCell align="right">
                                              <Checkbox
                                                onClick={() => handleAddListaOrdemServicosEnviarEmpenho(ordemServico.id, ordemServico.ordensServicosOrcamentos[0]?.valor, ordemServico.ordensServicosOrcamentos[0]?.idEmpresa)}
                                                color="primary"
                                                size="small"
                                                checked={isContainedListaOrdemServicosEnviarEmpenho(ordemServico.id)}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </React.Fragment>
                                  );
                                })
                              ) : (
                                <TableRow hover key={1}>
                                  <TableCell component="th" scope="row" align="center" colSpan={6}>
                                    Não há ordens de serviço aptas a receber empenhos
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </CardContent>
                  </>
                }
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12} align="center">
                      <Backdrop className={classes.backdrop} open={open}>
                        <Grid
                          container
                          spacing={3}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                          wrap="nowrap"
                        >
                          <Grid item>
                            <CircularProgress color="primary" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h4" color="textPrimary">
                              Enviando arquivo...
                            </Typography>
                          </Grid>
                        </Grid>
                      </Backdrop>
                      <FormLabel component="legend">Arquivo do Empenho</FormLabel>
                      <Input
                        id="caminhoArquivo"
                        className={classes.textField}
                        label="Arquivo do Empenho"
                        onChange={e => handleChangeFile(e)}
                        type="file"
                        placeholder="Enviar empenho"
                        formControl
                        margin="dense"
                      />
                      {returnNameArquivoEmpenho() ? <Typography className="left" style={{ fontSize: 12, marginTop: 3, cursor: 'pointer' }} onClick={() => handleOpenArquivo(props.empenho.caminhoArquivo)}>Arquivo atual: {returnNameArquivoEmpenho()}</Typography> : ''}
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12} align="center">
                      <Backdrop className={classes.backdrop} open={open}>
                        <Grid
                          container
                          spacing={3}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                          wrap="nowrap"
                        >
                          <Grid item>
                            <CircularProgress color="primary" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h4" color="textPrimary">
                              Enviando arquivo...
                            </Typography>
                          </Grid>
                        </Grid>
                      </Backdrop>
                      <FormLabel component="legend">Arquivo da NF</FormLabel>
                      <Input
                        id="caminhoNotaFiscal"
                        className={classes.textField}
                        label="Arquivo da NF"
                        placeholder='Arquivo da NF'
                        onChange={e => handleChangeFileNotaFiscal(e)}
                        type="file"
                        formControl
                        margin="dense"
                      />
                      {returnNameArquivoNF() ? <Typography className="left" style={{ fontSize: 12, marginTop: 3, cursor: 'pointer' }} onClick={() => handleOpenArquivo(props.empenho.caminhoNotaFiscal)}>Arquivo atual: {returnNameArquivoNF()}</Typography> : ''}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      {!idEmpenho ? '* Somente são exibidas na lista as ordens de serviço já concluídas e avaliadas' : (props.empenho.caminhoArquivo || props.empenho.caminhoNotaFiscal) ? 'Clique sobre o nome do arquivo para abri-lo em uma nova aba' : null}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlign: 'right',
                    justifyContent: 'right'
                  }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => history.push('/empenhos')}
                    >
                      Cancelar
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      dark={true}
                      type="submit"
                      disabled={props.empenho.isLoading}
                      startIcon={<SendIcon />}
                      endIcon={props.empenho.isLoading && <CircularProgress size={24} />}
                    >
                      {idEmpenho ? (props.empenho.isLoading ? 'Atualizando empenho...' : 'Atualizar') : (props.empenho.isLoading ? 'Enviando empenho...' : 'Enviar empenho')}
                    </CustomButton>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid >
      </Grid >
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.CADASTRO_EMPENHO}
          />
          :
          null
        )
      }
    </Page >
  )
}

EmpenhoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedEmpenhoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(EmpenhoEdit))
)
export { connectedEmpenhoEditPage as EmpenhoEdit }
