import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-18-pdf/renderer';
import { Header, Footer } from '../../_reports';
import moment from 'moment'
import { styled } from '@mui/material/styles';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    borderRight: 1,
    padding: 3,
    textAlign: 'center'
  }
});

const CustomPage = styled(Page)(({ theme }) => ({
  padding: 20,
  paddingBottom: 25,
  height: '100%',
  width: '100%'
}));

const RelatorioOcorrenciaOrdemServicoPrint = (props) => {

  const [ready, setReady] = React.useState(false);
  const { match: { params } } = props;
  const { relatorio } = props.relatorio

  useEffect(() => {

  }, [props.relatorio]);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1100);
  }, [])

  const onRendered = () => {
  };

  return (
    (ready &&
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document onRender={onRendered} width='100%'>
            <CustomPage orientation="landscape" size="A4" style={styles.page} wrap={true} >
              <Header
                title='Relatório de Ocorrências de uma Ordem de Serviço'
                info={<Text>ROTOR - FABRICAÇÃO, MANUTENÇÃO, REPARAÇÃO E FORNECIMENTO LTDA - 40.691.238/0001-25</Text>}
              />
              {
                relatorio.length > 0 ? relatorio.map((n, index) => {
                  var retorno = (
                    <React.Fragment key={index}>
                      {index === 0 && (
                        <>
                          <View style={[styles.row, { marginTop: 60 }]} >
                            <View style={[styles.header, { width: 100 }]}>
                              <Text>CÓDIGO</Text>
                            </View>
                            <View style={[styles.header, { width: 750 }]}>
                              <Text>ORDEM DE SERVIÇO</Text>
                            </View>
                          </View>
                          <View style={[styles.row]} >
                            <View style={[styles.cell, { width: 100 }]}>
                              <Text>{n.idOrdemServico}</Text>
                            </View>
                            <View style={[styles.cell, { width: 750 }]}>
                              <Text>{n.tituloOrdemServico} - {n.descritivoOrdemServico}</Text>
                            </View>
                          </View>
                        </>
                      )}
                      {index === 0 && (
                        <View style={[styles.row, { marginTop: 5 }]} >
                          <View style={[styles.header, { width: 100 }]}>
                            <Text>DATA</Text>
                          </View>
                          <View style={[styles.header, { width: 150 }]}>
                            <Text>OCORRÊNCIA</Text>
                          </View>
                          <View style={[styles.header, { width: 300 }]}>
                            <Text>USUÁRIO</Text>
                          </View>
                          <View style={[styles.header, { width: 150 }]}>
                            <Text>ÓRGÃO</Text>
                          </View>
                          <View style={[styles.header, { width: 150 }]}>
                            <Text>EMPRESA</Text>
                          </View>
                        </View>
                      )}
                      <View style={[styles.row]}>
                        <View style={[styles.cell, { width: 100 }]}>
                          <Text>{n.dataHora ? moment(n.dataHora).format('DD/MM/yyyy HH:mm') : '-'}</Text>
                        </View>
                        <View style={[styles.cell, { width: 150 }]}>
                          <Text>{n.descricaoOcorrencia ? n.descricaoOcorrencia.toUpperCase() : '-'}</Text>
                        </View>
                        <View style={[styles.cell, { width: 300, fontSize: 10 }]}>
                          <Text>{n.usuario ? n.usuario.toUpperCase() : '-'} ({n.usuarioEmail})</Text>
                        </View>
                        <View style={[styles.cell, { width: 150 }]}>
                          <Text>{n.orgao ? n.orgao.toUpperCase() : '-'}</Text>
                        </View>
                        <View style={[styles.cell, { width: 150 }]}>
                          <Text>{n.empresa ? n.empresa.toUpperCase() : '-'}</Text>
                        </View>
                      </View>
                    </React.Fragment>
                  );
                  return retorno;
                })
                  : <View style={[styles.row]}>
                    <View style={[styles.cell, { width: 850 }]}>
                      <Text>Nenhum registro encontrado</Text>
                    </View>
                  </View>
              }
              <Footer />
            </CustomPage>
          </Document>
        </PDFViewer>
      </div >
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioOcorrenciaOrdemServicoPrintPage = withRouter(connect(mapStateToProps, null, null, {
  pure: false
})(RelatorioOcorrenciaOrdemServicoPrint));
export { connectedRelatorioOcorrenciaOrdemServicoPrintPage as RelatorioOcorrenciaOrdemServicoPrint };
