import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const ordemServicoAction = {
  getOrdemServico,
  getOrdemServicoSemEmpenho,
  getOrdemServicoSelect,
  getOrdemServicoPublic,
  getOrdemServicoById,
  onChangeProps,
  editOrdemServicoInfo,
  editOrdemServicoConclui,
  editOrdemServicoVerifica,
  editOrdemServicoAprova,
  editOrdemServicoAprovaOrcamento,
  editOrdemServicoRecusa,
  editOrdemServicoRecusaOrcamento,
  editOrdemServicoDevolveParaRevisao,
  changeDetailsOrdemServico,
  editOrdemServicoAvalia,
  editOrdemServicoValida,
  createOrdemServico,
  editOrdemServicoMecanicoDelivery,
  deleteOrdemServicoById,
  clear,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getOrdemServicoSelect(filterModel) {
  return (dispatch) => {
    let apiEndpoint = 'ordensServico/select' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeOrdemServicosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function getOrdemServico(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeOrdemServicosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getOrdemServicoSemEmpenho(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/empenhos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeOrdemServicosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrdemServicoPublic(filterModelPublic) {
  return (dispatch) => {
    let apiEndpoint = `ordensServico/public${filterModelPublic}`
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeOrdemServicosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editOrdemServicoDevolveParaRevisao(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/devolveParaRevisao/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(getOrdemServicoById(id))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEM_SERVICO_FAILURE', error }
  }
}

function createOrdemServico(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServico'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(notIsLoading())
          if (response.data.key) {
            history.push('/ordemServico/' + response.data.key)
            dispatch(getOrdemServicoById(response.data.key))
          }
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function request(payload) {
    return { type: 'CREATE_ORDEMSERVICO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoMecanicoDelivery(payload, id) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServico/mecanicoDelivery/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(getOrdemServicoById(response.data.key))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'ORDEMSERVICO_CLEAR'
  }
}

function editOrdemServicoInfo(payload, id) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServico/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoConclui(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/finalizar/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoVerifica(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/verificar/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoAprova(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/aprova/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoValida(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/validar/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoAvalia(id, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/avaliacao/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoRecusa(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/recusa/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }
}

function editOrdemServicoAprovaOrcamento(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/aprova/orcamento/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoRecusaOrcamento(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/recusa/orcamento/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function changeDetailsOrdemServico(ordemServico) {
  return (dispatch) => {
    if (ordemServico) {
      dispatch(onChangeDetailsOrdemServico(ordemServico))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsOrdemServico(ordemServico) {
  return [
    {
      type: 'CHANGE_DETAILS_ORDEMSERVICO',
      id: ordemServico.id,
      titulo: ordemServico.titulo,
      descritivo: ordemServico.descritivo,
      dataInicio: ordemServico.dataInicio,
      dataTermino: ordemServico.dataTermino
    }
  ]
}

function request(payload) {
  return { type: 'EDIT_ORDEMSERVICO_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_ORDEMSERVICO_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
}


function getOrdemServicoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'ordensServico/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editOrdemServicosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}
function deleteOrdemServicoById(id, idOrgao, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/delete/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          history.push('/ordensServico')
          dispatch(getOrdemServico({ IdOrgao: idOrgao, Page: 0, Status: 8 }))
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }
}
export function changeOrdemServicosList(ordemServico, totalRows) {
  return {
    type: 'FETCHED_ALL_ORDEMSERVICO',
    ordemServico: ordemServico,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'ORDEMSERVICO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editOrdemServicosDetails(ordemServico) {
  return {
    type: 'ORDEMSERVICO_DETAIL',
    id: ordemServico.id,
    idTipoServico: ordemServico.idTipoServico,
    tipoServico: ordemServico.tipoServico,
    idVeiculo: ordemServico.idVeiculo,
    descritivo: ordemServico.descritivo,
    titulo: ordemServico.titulo,
    idUsuario: ordemServico.idUsuario,
    data: ordemServico.data,
    avaliacao: ordemServico.avaliacao,
    descritivoAvaliacao: ordemServico.descritivoAvaliacao,
    idUsuarioAvaliacao: ordemServico.idUsuarioAvaliacao,
    idOrgao: ordemServico.idOrgao,
    idEmpresa: ordemServico.idEmpresa,
    ultimoEvento: ordemServico.ultimoEvento,
    emergencia: ordemServico.emergencia,
    empresa: ordemServico.empresa,
    mecanicoDelivery: ordemServico.mecanicoDelivery,
    motivoExclusao: ordemServico.motivoExclusao,
    eventos: ordemServico.eventos,
    usuario: ordemServico.usuario,
    veiculo: ordemServico.veiculo,
    orgao: ordemServico.orgao,
    ordensServicosFotos: ordemServico.ordensServicosFotos,
    ordensServicosOrcamentos: ordemServico.ordensServicosOrcamentos,
    ordensServicosOcorrencias: ordemServico.ordensServicosOcorrencias,
    ordensServicosEmpenhos: ordemServico.ordensServicosEmpenhos
  }
}
export function createUserInfo() {
  return {
    type: 'ORDEMSERVICO_CREATED_SUCCESSFULLY'
  }
}
export function deleteOrdemServicosDetails() {
  return {
    type: 'DELETED_ORDEMSERVICO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'ORDEMSERVICO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'ORDEMSERVICO_NOTISLOADING'
  }
}
