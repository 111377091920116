import { crudService } from '../_services'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const manualAction = {
  getManual,
  getManualById,
  getManualByTela,
  onChangeProps,
  editManualInfo,
  changeDetailsManual,
  createManual,
  deleteManualById,
  clear,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getManual(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'manuais' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeManualsList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createManual(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'manuais'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          if (response.data.key) {
            dispatch(getManualById(response.data.key))
          }
        } else {
          //     toast.err('Oops! Erro ao cadastrar órgão! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //    toast.err('Oops! Erro ao cadastrar órgão! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_MANUAL_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_MANUAL_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'MANUAL_CLEAR'
  }
}

function editManualInfo(id, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'manuais/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/manuais')
        } else {
          //  toast.error('Oops! Erro ao alterar órgão! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //  toast.error('Oops! Erro ao alterar órgão! 😥')
      })
  }

  function request(payload) {
    return { type: 'EDIT_MANUAL_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'EDIT_MANUAL_FAILURE', error }
  }
}

function getManualById(id) {
  return (dispatch) => {
    let apiEndpoint = 'manuais/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editManualsDetails(response.data))
    })
  }
}

function getManualByTela(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'manuais/telas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeManualsList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}
function changeDetailsManual(manual) {
  return (dispatch) => {
    if (manual) {
      dispatch(onChangeDetailsManual(manual))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsManual(manual) {
  return [
    {
      type: 'CHANGE_DETAILS_MANUAL',
      id: manual.id,
      nome: manual.nome
    }
  ]
}

function deleteManualById(id, idOrgao) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'manuais/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteManualsDetails())
      history.push('/manuais')
      dispatch(getManual({ IdOrgao: idOrgao }, true))
      dispatch(notIsLoading())
    })
  }
}
export function changeManualsList(manual, totalRows) {
  return {
    type: 'FETCHED_ALL_MANUAL',
    manual: manual,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'MANUAL_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editManualsDetails(manual) {
  return {
    type: 'MANUAL_DETAIL',
    id: manual.id,
    isLoading: manual.isLoading,
    nome: manual.nome,
    categoria: manual.categoria,
    descricao: manual.descricao,
    link: manual.link,
    tiposUsuariosManuais: manual.tiposUsuariosManuais,
    excluido: manual.excluido
  }
}
export function createUserInfo() {
  return {
    type: 'MANUAL_CREATED_SUCCESSFULLY'
  }
}
export function deleteManualsDetails() {
  return {
    type: 'DELETED_MANUAL_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'MANUAL_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'MANUAL_NOTISLOADING'
  }
}


