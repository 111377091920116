import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Autocomplete,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItemButton,
  MenuItem,
  TextField,
  Typography,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { Page } from 'components'
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { estadoAction, ordemServicoAction, ordemServicoFotoAction, orgaoAction } from '../_actions'
import { editOrdemServicosDetails } from '../_actions/ordemServico.actions'
import logo from "../img/rotorSemFundo.png";
import CollectionsSharpIcon from '@mui/icons-material/CollectionsSharp';
import { Box } from '@mui/system'
import moment from 'moment/moment'
import CustomButton from '../components/CustomButton'
import CarouselImage from '../components/Carousel/CarouselImage'
import { faEraser } from '@fortawesome/free-solid-svg-icons'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: '-8px',
    height: '100vh'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid #0005',
    paddingBottom: '16px',
    marginBottom: '16px'
  },
  emptyList: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }
})

const OrdensServicoPublico = (props) => {

  const dispatch = useDispatch();

  const { classes } = props;

  const formMapRef = useRef(null);

  const { estado } = useSelector(
    (state) => state.estado
  )

  const { orgao } = useSelector(
    (state) => state.orgao
  )

  const optionsEstado = estado.map(u => ({ id: u.value, nome: u.text }));
  const optionsOrgao = orgao.map(u => ({ id: u.value, nome: u.text }));

  const [filtro, setFiltro] = useState({ idEstado: '', idOrgao: '', situacao: '' });
  const [carouselVisible, setCarouselVisible] = useState(false);

  const [valueEstado, setValueEstado] = React.useState(null);
  const [inputValueEstado, setInputValueEstado] = React.useState('');

  const [valueOrgao, setValueOrgao] = React.useState(null);
  const [inputValueOrgao, setInputValueOrgao] = React.useState('');

  useEffect(() => {
    dispatch(estadoAction.getEstadoSelect());
  }, []);

  useEffect(() => {
    if (filtro.idEstado) {
      dispatch(orgaoAction.getOrgaoSelectPublic(`?id=${filtro.idEstado}`));
    }
  }, [filtro.idEstado]);

  useEffect(() => {
    if (valueEstado) {
      handleChangeAutoComplete(valueEstado, estado, 'estado')
    }
  }, [valueEstado])

  useEffect(() => {
    if (valueOrgao) {
      handleChangeAutoComplete(valueOrgao, orgao, 'orgao')
    }
  }, [valueOrgao])

  const handleChangeAutoComplete = (obj, array, nameArray) => {
    if (obj != null) {
      let selected = array.filter(
        (item) => item.value == obj.id
      )

      if (nameArray == 'estado') {
        setFiltro({ ...filtro, idEstado: selected[0].value })
      } else if (nameArray == 'orgao') {
        setFiltro({ ...filtro, idOrgao: selected[0].value })
      }

    } else {

    }
  }

  useEffect(() => {
    if (filtro.idOrgao != '' && (filtro.situacao == 1 || filtro.situacao == 2 || filtro.situacao == 3)) {
      dispatch(ordemServicoAction.getOrdemServicoPublic(`?id=${filtro.idOrgao}&situacao=${filtro.situacao}`));
    }

  }, [filtro.situacao]);

  const handleOpenCarousel = () => {
    dispatch(ordemServicoFotoAction.getOrdemServicoFotoPublic({ Id: props.ordemServico?.id }));
    setCarouselVisible(true);
  }

  const handleClean = () => {
    setFiltro({})
    setValueEstado(null)
    setValueOrgao(null)
  }

  const renderListObras = () => {
    return (
      <List>
        {filtro.idOrgao != '' && filtro.idOrgao != 0 && filtro.situacao && filtro.situacao != '' && props.ordemServico.ordemServico && props.ordemServico.ordemServico.length > 0 && props.ordemServico.ordemServico != undefined ? props.ordemServico.ordemServico.map((ordemServico) => {
          return (
            <Box key={ordemServico.id} mb={3}>
              <ListItemButton
                selected={ordemServico.id === props.ordemServico.id}
                onClick={() => dispatch(editOrdemServicosDetails(ordemServico))}
                className={classes.listItem}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="15px"
                  style={{ width: '100%' }}
                >
                  <img src={ordemServico?.caminhoImagem || logo} width={100} height={100} style={{ borderRadius: '5px', objectFit: ordemServico.caminhoImagem ? 'cover' : 'contain' }} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    style={{ width: '100%' }}
                  >
                    <Typography variant="h5" mb={1} style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}><strong>{ordemServico.titulo}</strong></Typography>
                    <Typography fontSize="13px">INÍCIO: {moment(ordemServico?.dataInicio).format('DD/MM/YYYY')}</Typography>
                    <Typography fontSize="13px">TÉRMINO: {moment(ordemServico?.dataTermino).format('DD/MM/YYYY')}</Typography>
                    <Typography fontSize="13px">TIPO DE SERVIÇO: {ordemServico?.tipoServico?.nome.toUpperCase()}</Typography>
                    <Typography fontSize="13px">VEÍCULO: {ordemServico?.veiculo?.placa.toUpperCase()}</Typography>
                    <Typography fontSize="13px">EMPRESA: {ordemServico?.empresa?.razaoSocial ? ordemServico.empresa.razaoSocial.toUpperCase() : 'AINDA NÃO TEVE ORÇAMENTO APROVADO'}</Typography>
                    <Typography fontSize="13px">VALOR: {ordemServico?.ordensServicosOrcamentos[0]?.valor ? 'R$ ' + ordemServico?.ordensServicosOrcamentos[0]?.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 'AINDA NÃO TEVE ORÇAMENTO APROVADO'}</Typography>
                  </Box>
                </Box>
                {props.ordemServico?.id === ordemServico.id &&
                  <CustomButton dark style={{ marginTop: '20px', width: '100%' }} onClick={handleOpenCarousel}>
                    <CollectionsSharpIcon />
                    <Typography variant="p" ml={1} fontSize="12px">FOTOS DA ORDEM DE SERVIÇO</Typography>
                  </CustomButton>
                }
              </ListItemButton>
              <Divider />
            </Box>
          );
        })
          : !filtro.situacao ?
            <FormLabel>
              <Typography style={{ justifyContent: 'center', textAlign: 'center' }}>
                Selecione uma situação para filtrar
              </Typography>
            </FormLabel>
            :
            <FormLabel>
              <Typography style={{ justifyContent: 'center', textAlign: 'center' }}>
                Não há ordens de serviço para exibir
              </Typography>
            </FormLabel>
        }
      </List>
    )
  }

  const renderEmptyList = () => {
    return (
      <div className={classes.emptyList}>
        <FormLabel>
          <Typography variant="p" align="center">{filtro.idOrgao ? 'Sem ordens de serviço até o momento' : 'Escolha um órgão público'}</Typography>
        </FormLabel>
      </div>
    );
  }

  return (
    <Page className={classes.root} title="Acompanhar Ordens de Serviço">
      {carouselVisible && <CarouselImage images={props.ordemServicoFoto.ordemServicoFoto} openCarousel={carouselVisible} handleClose={() => setCarouselVisible(false)} />}
      <Grid container spacing={2} style={{ justifyContent: 'center' }}>
        <Grid item lg={8} md={8} xl={8} xs={12} style={{ justifyContent: 'center' }}>
          <Grid item gridRow={1}>
            <div className={classes.logo}>
              <img src={logo} width={250} />
            </div>
            <Typography variant="h5" align="center">Acompanhar Ordens de Serviço Públicas</Typography>
            {estado?.length > 0 &&
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <ValidatorForm
                  className={classes.form}
                  ref={formMapRef}
                  id="formMap"
                  onSubmit={(event) => console.log(event)}
                >
                  <Grid container direction="row" spacing={2}>
                    <Grid item lg={2} md={2} xl={2} xs={3} style={{ marginTop: 16 }}>
                      <Autocomplete
                        value={valueEstado}
                        onChange={(event, newValueEstado) => {
                          setValueEstado(newValueEstado);
                        }}
                        inputValue={inputValueEstado}
                        onInputChange={(event, newInputValueEstado) => {
                          setInputValueEstado(newInputValueEstado);
                        }}
                        id="controllable-states-demo"
                        options={optionsEstado}
                        margin="normal"
                        variant="outlined"
                        defaultValue={valueEstado}
                        isOptionEqualToValue={(option, value) => option.id == value.id}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextField required {...params} label="Selecione um estado" fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={7} style={{ marginTop: 16 }}>
                      <Autocomplete
                        value={valueOrgao}
                        onChange={(event, newValueOrgao) => {
                          setValueOrgao(newValueOrgao);
                        }}
                        inputValue={inputValueOrgao}
                        onInputChange={(event, newInputValueOrgao) => {
                          setInputValueOrgao(newInputValueOrgao);
                        }}
                        id="controllable-states-demo"
                        options={optionsOrgao}
                        margin="normal"
                        variant="outlined"
                        defaultValue={valueOrgao}
                        isOptionEqualToValue={(option, value) => option.id == value.id}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextField required {...params} label="Selecione um órgão" fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={4}>
                      <SelectValidator
                        className={classes.textField}
                        variant="outlined"
                        id="situacao"
                        label="Situação"
                        value={filtro.situacao}
                        onChange={(event) => setFiltro({ ...filtro, situacao: event.target.value })}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                      >
                        <MenuItem disabled value="">
                          <em>Situação</em>
                        </MenuItem>
                        <MenuItem key={1} value={1}>EM ANDAMENTO</MenuItem>
                        <MenuItem key={2} value={2}>CONCLUÍDA</MenuItem>
                        <MenuItem key={3} value={3}>AVALIADA</MenuItem>
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={1} md={1} xl={1} xs={2}>
                      <Tooltip title="Limpar">
                        <IconButton onClick={handleClean} style={{ marginTop: 20 }}>
                          <FontAwesomeIcon icon={faEraser} style={{ color: '#404040', fontSize: 25 }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Grid>
            }
          </Grid>
          <Grid item gridRow={2} overflow="scroll" style={{ overflowX: 'hidden' }}>
            {filtro.idOrgao > 0 ? renderListObras() : renderEmptyList()}
          </Grid>
          <Grid item align="right" style={{ justifyContent: 'right', marginTop: 40 }}>
            <Typography fontSize="11px">* Para que a ordem de serviço seja exibida, é necessário que o órgão tenha liberado o seu acesso como público</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Page >
  )
}
OrdensServicoPublico.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedOrdensServicoPublicoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdensServicoPublico))
)
export { connectedOrdensServicoPublicoPage as OrdensServicoPublico }
