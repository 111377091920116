const initialState = {
  totalRegistros: 0,
  isLoading: false,
  ordemServicoOrcamento: [],
  open: false,
  id: '',
  data: '',
  prazo: '',
  valor: 0,
  statusOrcamento: 0,
  idOrgao: 0,
  idOrdemServico: 0,
  possuiGarantia: false,
  observacoesGarantia: '',
  caminhoAnexo: '',
  idEmpresa: 0,
  motivoExclusao: '',
  empresa: [],
  excluido: false,
  ordensServicosOrcamentosAnexos: [],
  observacoes: ''
}

export function ordemServicoOrcamento(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ORDEMSERVICO_ORCAMENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_ORDEMSERVICO_ORCAMENTO_SUCCESS':
      return {
        ...state,
        id: action.ordemServicoOrcamento.id,
        isLoading: false,
        data: action.ordemServicoOrcamento.data,
        prazo: action.ordemServicoOrcamento.data,
        valor: action.ordemServicoOrcamento.valor,
        statusOrcamento: action.ordemServicoOrcamento.statusOrcamento,
        idOrgao: action.ordemServicoOrcamento.idOrgao,
        idOrdemServico: action.ordemServicoOrcamento.idOrdemServico,
        idEmpresa: action.ordemServicoOrcamento.idEmpresa,
        excluido: action.ordemServicoOrcamento.excluido,
        empresa: action.ordemServicoOrcamento.empresa,
        motivoExclusao: action.ordemServicoOrcamento.motivoExclusao,
        observacoes: action.ordemServicoOrcamento.observacoes,
        ordensServicosOrcamentosAnexos: action.ordemServicoOrcamento.ordensServicosOrcamentosAnexos,
        possuiGarantia: action.ordemServicoOrcamento.possuiGarantia,
        observacoesGarantia: action.ordemServicoOrcamento.observacoesGarantia,
        caminhoAnexo: action.ordemServicoOrcamento.caminhoAnexo
      }
    case 'CREATE_ORDEMSERVICO_ORCAMENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_ORDEMSERVICO_ORCAMENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_ORDEMSERVICO_ORCAMENTO_SUCCESS':
      return {
        ...state,
        id: action.ordemServicoOrcamento.id,
        isLoading: false,
        data: action.ordemServicoOrcamento.data,
        prazo: action.ordemServicoOrcamento.data,
        valor: action.ordemServicoOrcamento.valor,
        statusOrcamento: action.ordemServicoOrcamento.statusOrcamento,
        idOrgao: action.ordemServicoOrcamento.idOrgao,
        idOrdemServico: action.ordemServicoOrcamento.idOrdemServico,
        idEmpresa: action.ordemServicoOrcamento.idEmpresa,
        excluido: action.ordemServicoOrcamento.excluido,
        empresa: action.ordemServicoOrcamento.empresa,
        observacoes: action.ordemServicoOrcamento.observacoes,
        motivoExclusao: action.ordemServicoOrcamento.motivoExclusao,
        possuiGarantia: action.ordemServicoOrcamento.possuiGarantia,
        ordensServicosOrcamentosAnexos: action.ordemServicoOrcamento.ordensServicosOrcamentosAnexos,
        observacoesGarantia: action.ordemServicoOrcamento.observacoesGarantia,
        caminhoAnexo: action.ordemServicoOrcamento.caminhoAnexo
      }
    case 'EDIT_ORDEMSERVICO_ORCAMENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_ORDEMSERVICO_ORCAMENTO':
      return {
        ...state,
        ordemServicoOrcamento: action.ordemServicoOrcamento,
        totalRegistros: action.totalRegistros
      }
    case 'ORDEMSERVICO_ORCAMENTO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        data: action.data,
        prazo: action.data,
        valor: action.valor,
        statusOrcamento: action.statusOrcamento,
        idOrgao: action.idOrgao,
        idOrdemServico: action.idOrdemServico,
        idEmpresa: action.idEmpresa,
        excluido: action.excluido,
        motivoExclusao: action.motivoExclusao,
        empresa: action.empresa,
        observacoes: action.observacoes,
        possuiGarantia: action.possuiGarantia,
        observacoesGarantia: action.observacoesGarantia,
        ordensServicosOrcamentosAnexos: action.ordensServicosOrcamentosAnexos,
        caminhoAnexo: action.caminhoAnexo
      }
    case 'ORDEMSERVICO_ORCAMENTO_CLEAR':
      return initialState
    case 'ORDEMSERVICO_ORCAMENTO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        ordemServicoOrcamento: [...state.ordemServicoOrcamento, action.data.ordemServicoOrcamento]
      }
    case 'ORDEMSERVICO_ORCAMENTO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ORDEMSERVICO_ORCAMENTO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'ORDEMSERVICO_ORCAMENTO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
