import { authentication } from './auth.reducer'
import { cidade } from './cidade.reducer'
import { dashboard } from './dashboard.reducer'
import { estado } from './estado.reducer'
import { empenho } from './empenho.reducer'
import { empresa } from './empresa.reducer'
import { empresaAnexo } from './empresaAnexo.reducer'
import { empresaOrgao } from './empresaOrgao.reducer'
import { marca } from './marca.reducer'
import { notificacao } from './notificacao.reducer'
import { notificacaoFiltro } from './notificacaoFiltro.reducer'
import { notificacaoUsuario } from './notificacaoUsuario.reducer'
import { notificacaoTeste } from './notificacaoTeste.reducer'
import { orgao } from './orgao.reducer'
import { ordemServico } from './ordemServico.reducer'
import { ordemServicoCobranca } from './ordemServicoCobranca.reducer'
import { ordemServicoOrcamento } from './ordemServicoOrcamento.reducer'
import { ordemServicoOcorrencia } from './ordemServicoOcorrencia.reducer'
import { ordemServicoFoto } from './ordemServicoFoto.reducer'
import { parametro } from './parametro.reducer'
import { permissao } from './permissao.reducer'
import { permissaoTipoUsuario } from './permissaoTipoUsuario.reducer'
import { permissaoUsuario } from './permissaoUsuario.reducer'
import { relatorio } from './relatorio.reducer'
import { subOrgao } from './subOrgao.reducer'
import { tipoAnexo } from './tipoAnexo.reducer'
import { tipoCombustivel } from './tipoCombustivel.reducer'
import { tipoFoto } from './tipoFoto.reducer'
import { tipoUsuario } from './tipoUsuario.reducer'
import { tipoServico } from './tipoServico.reducer'
import { tipoVeiculo } from './tipoVeiculo.reducer'
import { usuario } from './usuario.reducer'
import { usuarioOrgao } from './usuarioOrgao.reducer'
import { usuarioEmpresa } from './usuarioEmpresa.reducer'
import { veiculo } from './veiculo.reducer'
import { manual } from './manual.reducer'

import { combineReducers } from 'redux'

import { reducer as formReducer } from 'redux-form'

const appReducer = combineReducers({
  authentication,
  cidade,
  dashboard,
  estado,
  empenho,
  empresa,
  empresaAnexo,
  empresaOrgao,
  manual,
  marca,
  notificacao,
  notificacaoFiltro,
  notificacaoUsuario,
  notificacaoTeste,
  ordemServico,
  ordemServicoCobranca,
  ordemServicoFoto,
  ordemServicoOrcamento,
  ordemServicoOcorrencia,
  orgao,
  parametro,
  permissao,
  permissaoTipoUsuario,
  permissaoUsuario,
  relatorio,
  subOrgao,
  tipoAnexo,
  tipoCombustivel,
  tipoServico,
  tipoUsuario,
  tipoFoto,
  tipoVeiculo,
  usuario,
  usuarioOrgao,
  usuarioEmpresa,
  veiculo,
  form: formReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    const { routing } = state
    state = { routing }
  }
  return appReducer(state, action)
}
export default rootReducer
