const initialState = {
  totalRegistros: 0,
  isLoading: false,
  ordemServicoOcorrencia: [],
  id: 0,
  data: '',
  idOrdemServico: 0,
  ordemServico: [],
  idEmpresa: 0,  
  empresa: [],
  idOrgao: 0,
  orgao: [],
  descricao: '',
  excluido: false,
  idUsuario: 0,
  sistema: false
}

export function ordemServicoOcorrencia(state = initialState, action) {
  switch (action.type) {    
    case 'FETCHED_ALL_ORDEM_SERVICO_OCORRENCIA':
      return {
        ...state,
        ordemServicoOcorrencia: action.ordemServicoOcorrencia,
        totalRegistros: action.totalRegistros
      }   
    case 'ORDEM_SERVICO_OCORRENCIA_CLEAR':
      return initialState   
    case 'ORDEM_SERVICO_OCORRENCIA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ORDEM_SERVICO_OCORRENCIA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'ORDEM_SERVICO_OCORRENCIA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
