import { STATUS_SERVICO } from "./constants";

export function getNomeEvento(evento) {
  try {
    if (evento.tipo == STATUS_SERVICO.LANCAMENTO) {
      return "EM LANÇAMENTO"
    } else if (evento.tipo == STATUS_SERVICO.PENDENTE) {
      return "PENDENTE";
    } else if (evento.tipo == STATUS_SERVICO.RECEBENDO_ORCAMENTOS) {
      return "RECEBENDO ORÇAMENTOS";
    } else if (evento.tipo == STATUS_SERVICO.ORCAMENTO_APROVADO) {
      return "EM EXECUÇÃO";
    } else if (evento.tipo == STATUS_SERVICO.RECUSADO) {
      return "RECUSADA";
    } else if (evento.tipo == STATUS_SERVICO.CONCLUIDO) {
      return "CONCLUÍDA";
    } else if (evento.tipo == STATUS_SERVICO.AVALIADO) {
      return "AVALIADA";
    } else if (evento.tipo == STATUS_SERVICO.VERIFICADO) {
      return "VERIFICADA";
    } else if (evento.tipo == STATUS_SERVICO.DESERTO) {
      return "DESERTA";
    } else if (evento.tipo == STATUS_SERVICO.SEM_SUCESSO) {
      return "SEM SUCESSO";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}

export function getNomeUltimoEvento(ultimoEvento) {
  try {
    if (ultimoEvento == STATUS_SERVICO.LANCAMENTO) {
      return 'EM LANÇAMENTO';
    } else if (ultimoEvento == STATUS_SERVICO.PENDENTE) {
      return "PENDENTE";
    } else if (ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS) {
      return "RECEBENDO ORÇAMENTOS";
    } else if (ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO) {
      return "EM EXECUÇÃO";
    } else if (ultimoEvento == STATUS_SERVICO.RECUSADO) {
      return "RECUSADA";
    } else if (ultimoEvento == STATUS_SERVICO.CONCLUIDO) {
      return "CONCLUÍDA";
    } else if (ultimoEvento == STATUS_SERVICO.AVALIADO) {
      return "AVALIADA";
    } else if (ultimoEvento == STATUS_SERVICO.VERIFICADO) {
      return "VERIFICADA";
    } else if (ultimoEvento == STATUS_SERVICO.DESERTO) {
      return "DESERTA";
    } else if (ultimoEvento == STATUS_SERVICO.SEM_SUCESSO) {
      return "SEM SUCESSO";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}

export function getNomeUltimoEventoFormatadoLista(ultimoEvento, ordemServico) {
  try {
    if (ultimoEvento == STATUS_SERVICO.LANCAMENTO) {
      return 'EM LANÇAMENTO';
    } else if (ultimoEvento == STATUS_SERVICO.PENDENTE) {
      return "PENDENTE";
    } else if (ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS) {
      return 'RECEBENDO ORÇAMENTOS (' + ordemServico?.ordensServicosOrcamentos?.length + ')';
    } else if (ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO) {
      return "EM EXECUÇÃO";
    } else if (ultimoEvento == STATUS_SERVICO.RECUSADO) {
      return "RECUSADA/EXCLUÍDA";
    } else if (ultimoEvento == STATUS_SERVICO.CONCLUIDO) {
      return "CONCLUÍDA";
    } else if (ultimoEvento == STATUS_SERVICO.AVALIADO) {
      return "AVALIADA";
    } else if (ultimoEvento == STATUS_SERVICO.VERIFICADO) {
      return "VERIFICADA";
    } else if (ultimoEvento == STATUS_SERVICO.DESERTO) {
      return "DESERTA";
    } else if (ultimoEvento == STATUS_SERVICO.SEM_SUCESSO) {
      return "SEM SUCESSO";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}

export function getCor(ultimoEvento) {
  try {
    if (ultimoEvento == STATUS_SERVICO.LANCAMENTO || ultimoEvento === "EM LANÇAMENTO") {
      return "#5E5E5E";
    } else if (ultimoEvento == STATUS_SERVICO.PENDENTE || ultimoEvento === "PENDENTE") {
      return "#9E9E9E";
    } else if (ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS || ultimoEvento === "RECEBENDO ORÇAMENTOS") {
      return "#673AB7";
    } else if (ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO || ultimoEvento === "EM EXECUÇÃO") {
      return "#2196F3";
    } else if (ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO || ultimoEvento === "RECUSADA") {
      return "#FF5722";
    } else if (ultimoEvento == STATUS_SERVICO.CONCLUIDO || ultimoEvento === "CONCLUÍDA") {
      return "#99FF99";
    } else if (ultimoEvento == STATUS_SERVICO.AVALIADO || ultimoEvento === "AVALIADA") {
      return "#FF9800";
    } else if (ultimoEvento == STATUS_SERVICO.VERIFICADO || ultimoEvento === "VERIFICADA") {
      return "#4CAF50";
    } else if (ultimoEvento == STATUS_SERVICO.DESERTO || ultimoEvento === "DESERTA") {
      return "#FF9999";
    } else if (ultimoEvento == STATUS_SERVICO.SEM_SUCESSO || ultimoEvento === "SEM SUCESSO") {
      return "#FF2222";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}
