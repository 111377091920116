import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../components/CustomButton/CustomButton'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import HelpIcon from '@mui/icons-material/Help';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Chip,
  Divider,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  TextField
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
} from 'react-material-ui-form-validator'
import { styled } from '@mui/material/styles';
import {
  manualAction,
  tipoUsuarioAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { MANUAIS_CATEGORIAS, TIPO_USUARIO, history, stringUtils } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@mui/icons-material/Save';
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const CustomCardActions = styled(CardActions)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    textAlign: 'center'
  },
}));

const CustomGridButtonsAction = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'right',
  flexDirection: 'row',
  textAlign: 'right',
  justifyContent: 'right',
  [theme.breakpoints.down('sm')]: {
    paddingTop: 3,
    display: 'block',
    textAlign: 'center'
  },
}));

const ManualEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, orgao, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const [selectedTiposUsuarios, setSelectedTiposUsuarios] = useState([]);

  const [valueCategoria, setValueCategoria] = useState(null);
  const [inputValueCategoria, setInputValueCategoria] = useState('');

  const [, forceUpdate] = React.useState(0);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const options = Object.keys(MANUAIS_CATEGORIAS)
    .map(key => ({
      id: MANUAIS_CATEGORIAS[key],
      nome: key.replace(/_/g, ' ').toUpperCase()
    }))
    .sort((a, b) => a.nome.localeCompare(b.nome)); // ordena em ordem alfabética as opções

  useEffect(() => {

    if (params.id) {
      dispatch(manualAction.getManualById(params.id))
      dispatch(tipoUsuarioAction.getTiposUsuarioSelect())

      if (props.manual && props.manual.tiposUsuariosManuais && props.manual.tiposUsuariosManuais.length > 0) {
        setSelectedTiposUsuarios(props.manual.tiposUsuariosManuais)
      }

    } else {
      dispatch(manualAction.clear())
      dispatch(tipoUsuarioAction.clear())
    }

  }, []);

  useEffect(() => {
    if (params.id != undefined && props.manual.categoria != 0) {
      setValueCategoria({
        'id': options?.find(m => m.id == props.manual.categoria)?.id,
        'nome': options?.find(m => m.id == props.manual.categoria)?.nome
      })
    }
  }, [props.manual.categoria])

  const tiposUsuarios = useSelector((state) => state.tipoUsuario.tipoUsuario)

  const handleChange = (prop) => (event) => {
    dispatch(manualAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    if (valueCategoria) {
      handleChangeAutoComplete(valueCategoria)
    }
  }, [valueCategoria])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selected = options.filter(
        (item) => item.id == obj.id
      )

      dispatch(manualAction.changeDetailsManual(selected[0]))


    } else {

    }
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.manual.nome,
      descricao: props.manual.descricao,
      categoria: valueCategoria.id,
      link: props.manual.link,
      tiposUsuariosManuais: selectedTiposUsuarios
    }

    if (params.id) {
      dispatch(manualAction.editManualInfo(params.id, payload, true))
    } else {
      dispatch(manualAction.createManual(payload))
    }

  };

  function handleChangeTipoUsuario(id) {
    const isSelected = selectedTiposUsuarios?.some((n) => n.idTipoUsuario == id)
    if (isSelected) {
      const newSelected = selectedTiposUsuarios?.filter((n) => n.idTipoUsuario != id)
      setSelectedTiposUsuarios(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idTipoUsuario: id,
        idManual: props.manual.id
      }
      setSelectedTiposUsuarios([...selectedTiposUsuarios, newSelecteds])
    }
  }

  useEffect(() => {
    props.manual.tiposUsuariosManuais = selectedTiposUsuarios;
    forceUpdate(n => !n);
  }, [selectedTiposUsuarios])


  const isTipoUsuarioSelected = (id) => {
    if (id != undefined) {
      return (props.manual?.tiposUsuariosManuais?.some((n) => n.idTipoUsuario == id) || selectedTiposUsuarios?.some((n) => n.idTipoUsuario == id))
    }
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Manual'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Manual'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Manuais - Editar Manual'
          : 'Gerência de Manuais - Novo Manual'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formManual"
                onSubmit={(event) => handleSubmit(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Manuais do Sistema"
                    title={<SegHeader />}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.manual.nome ? props.manual.nome.toUpperCase() : ''}
                        variant="outlined"
                        validators={['required', 'maxStringLength: 100']}
                        errorMessages={['Campo obrigatório!', 'Tamanho máximo do campo só pode ser de até 100 caracteres']}
                      />
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12} >
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="descricao"
                        label="Descrição"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('descricao')}
                        value={props.manual.descricao ? props.manual.descricao.toUpperCase() : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={9} md={9} xl={9} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="link"
                        label="Link *"
                        margin="normal"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        onChange={handleChange('link')}
                        value={props.manual.link}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12} style={{ marginTop: 16 }}>
                      <Autocomplete
                        value={valueCategoria}
                        onChange={(event, newValueCategoria) => {
                          setValueCategoria(newValueCategoria);
                        }}
                        inputValue={inputValueCategoria}
                        onInputChange={(event, newInputValueCategoria) => {
                          setInputValueCategoria(newInputValueCategoria);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        margin="normal"
                        variant="outlined"
                        defaultValue={valueCategoria}
                        isOptionEqualToValue={(option, value) => option.id == value.id}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextField required {...params} label="Selecione em qual tela deseja exibir o manual" fullWidth />}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  {params.id &&
                    <>
                      <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                        <Divider />
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography component="h4" variant="subtitle" align="center">
                          Tipos de usuários que terão acesso ao manual *
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} align="center">
                        {tiposUsuarios && tiposUsuarios != undefined && tiposUsuarios.length > 0 &&
                          tiposUsuarios.map((n) => (
                            //O ADMIN JÁ TERÁ ACESSO A TUDO, ENTÃO NÃO VAI PRECISAR SELECIONAR ELE
                            n.value != TIPO_USUARIO.ADMIN ?
                              <Chip label={n.text} onClick={() => handleChangeTipoUsuario(n.value)}
                                variant={isTipoUsuarioSelected(n.value) ? "filled" : "outlined"} style={{ backgroundColor: isTipoUsuarioSelected(n.value) && '#F2D647', color: isTipoUsuarioSelected(n.value) && '#000000', margin: 5 }} />
                              : null))
                        }
                      </Grid>
                    </>
                  }
                </CardContent>
                <Divider />
                <Typography style={{ fontSize: "10px", paddingLeft: "16px", marginTop: "12px" }}>
                  * Campos obrigatórios
                </Typography>
                <CustomCardActions>
                  <CustomGridButtonsAction item lg={12} md={12} xl={12} xs={12}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => history.push('/manuais')}
                    >
                      Cancelar
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      dark={true}
                      type="submit"
                      disabled={props.manual.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.manual.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.manual.isLoading ? 'Atualizando manual...' : 'Atualizar') : (props.manual.isLoading ? 'Salvando manual...' : 'Salvar')}
                    </CustomButton>
                  </CustomGridButtonsAction>
                </CustomCardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid >
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.CADASTRO_AJUDA}
          />
          :
          null
        )
      }
    </Page >
  )
}

ManualEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedManualEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(ManualEdit))
)
export { connectedManualEditPage as ManualEdit }
