import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Typography from '@mui/material/Typography'
import { uploadFile } from '_services'
import HelpIcon from '@mui/icons-material/Help';
import {
  Autocomplete,
  Backdrop,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  FormLabel,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Paper,
  Switch,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TextField,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import {
  authActions,
  empresaAction,
  ordemServicoOrcamentoAction,
  ordemServicoAction,
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { STATUS_SERVICO, history, stringUtils, placaMask, MANUAIS_CATEGORIAS } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify'
import CustomButton from '../../components/CustomButton';
import { OrdemServicoOrcamentoAnexoEmpresaModal } from './ordemServicoOrcamentoAnexoEmpresaModal.component'
import { EmpresaAceiteTermosModal } from 'views/Empresas/empresaAceiteTermosModal.component';
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
}));

const CustomButton2 = styled(Button)(() => ({
  color: '#000',
  backgroundColor: '#FFF',
  border: 'solid 1px #000',
  borderRadius: 4,
  padding: 7,
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#000',
    color: '#FFF'
  }
}));

const SwitchBlack = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    fontSize: 12,
    '&.Mui-checked': {
      color: '#000'
    }
  }
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: -10,
    marginBottom: -8,
    paddingTop: -10,
    paddingBottom: -8,
    paddingRight: 3,
    marginRight: 3,
    paddingLeft: 3,
    marginLeft: 3
  },
}));

const OrdemServicoOrcamentoEdit = (props) => {

  const dispatch = useDispatch()
  const [modalConfirm, setModalConfirm] = useState(false);

  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [file, setFile] = useState(undefined)
  const [openUpload, setOpenUpload] = useState(false)

  const location = useLocation();
  const idOrdemServico = location.state;

  const [cont, setCont] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState([]);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const [diasExecucao, setDiasExecucao] = React.useState(null);
  const [valueEmpresa, setValueEmpresa] = React.useState(null);
  const [inputValueEmpresa, setInputValueEmpresa] = React.useState('');
  const [optionsEmpresa, setOptionsEmpresa] = React.useState([]);
  const [modalConfirmTermos, setModalConfirmTermos] = useState(false);

  const toggleAceiteTermos = () => setModalConfirmTermos(!modalConfirmTermos);

  const [open, setOpen] = React.useState(false)

  const [valorOrcamento, setValorOrcamento] = useState(0);

  const { ordemServicoOrcamento } = useSelector(
    (state) => state.ordemServicoOrcamento)

  const { empresa } = useSelector(
    (state) => state.empresa
  )

  const handleOpenModal = () => {
    toggle();
  }

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const verifyEmpresaAceitouTermos = () => {
    if (props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa) {
      return empresa?.some(em => props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa == parseInt(em.value) && em.excluido != true && em.aceiteTermos == true);
    }
    return false;
  }

  const handleChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  const handleUploadClickFiles = (event) => {
    const files = Array.from(event.target.files); // Transformando em array para iteração

    setOpen(true);

    // Array para armazenar as promessas de upload
    const uploadPromises = files.map((file) =>
      uploadFile(file)
        .then((response) => {
          return { caminhoArquivo: response.toString() };
        })
        .catch((err) => {
          console.log(err);
          return null;
        })
    );

    // Esperar que todos os uploads sejam concluídos
    Promise.all(uploadPromises)
      .then((uploadedFiles) => {
        // Filtrar arquivos que tiveram sucesso no upload (não são null)
        const successfulUploads = uploadedFiles.filter((file) => file !== null);
        setFilesUploaded([...filesUploaded, ...successfulUploads]);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };


  useEffect(() => {

    setOptionsEmpresa(empresa.map(u => ({ id: u.value, nome: u.text })));

    dispatch(empresaAction.getEmpresaSelect())

    if (params.id) {
      dispatch(ordemServicoOrcamentoAction.getOrdemServicoOrcamentoById(params.id))
    } else {
      dispatch(ordemServicoOrcamentoAction.clear())
      setValorOrcamento(0);
      setCont(0);

      let changeLatEvent = {
        type: 'text',
        target: {
          value: moment().format('yyyy-MM-DD')
        }
      };
      dispatch(ordemServicoOrcamentoAction.onChangeProps('data', changeLatEvent));

      if (props.ordemServico.dataTermino && props.ordemServico.dataInicio) {
        changeLatEvent = {
          type: 'text',
          target: {
            value: moment(props.ordemServico.dataTermino).diff(moment(props.ordemServico.dataInicio), 'days')
          }
        };
        dispatch(ordemServicoOrcamentoAction.onChangeProps('diasPrazo', changeLatEvent));

        changeLatEvent = {
          type: 'text',
          target: {
            value: props.ordemServico.dataTermino
          }
        };
        dispatch(ordemServicoOrcamentoAction.onChangeProps('prazo', changeLatEvent));
      }
    }

    if (idOrdemServico) {
      dispatch(ordemServicoAction.getOrdemServicoById(idOrdemServico))
    }

  }, []);

  const handleChange = (prop) => (event) => {
    dispatch(ordemServicoOrcamentoAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    if (idOrdemServico && idOrdemServico != 0 && (params.id == undefined || params.id == 0)) {
      setValorOrcamento(0);
      setCont(0);
    }

  }, [])

  useEffect(() => {
    if (props.ordemServicoOrcamento.idEmpresa != 0 && params.id != undefined) {
      setValueEmpresa(props.ordemServicoOrcamento.empresa)
    }

  }, [props.ordemServicoOrcamento.idEmpresa])

  useEffect(() => {
    if (valueEmpresa) {
      handleChangeAutoCompleteEmpresa(valueEmpresa)
    }
  }, [valueEmpresa])

  const handleChangeAutoCompleteEmpresa = (obj) => {
    if (obj != null) {
      let selected = empresa.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        empresaAction.changeDetailsEmpresa(selected[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  useEffect(() => {
    if (props.ordemServicoOrcamento.data && diasExecucao) {
      const day = moment(props.ordemServicoOrcamento.data).add(diasExecucao, 'days');
      props.ordemServicoOrcamento.prazo = moment(day).format("yyyy-MM-DD");
    }
  }, [diasExecucao, props.ordemServicoOrcamento.data])

  const handleModalConfirm = (event) => {

    if (!diasExecucao) {
      toast.error('Informe um prazo para o orçamento')
      return;
    }

    if (!valorOrcamento) {
      toast.error('O valor do orçamento não poder ficar em branco')
      return;
    }

    setModalConfirm(true);
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      data: moment(props.ordemServicoOrcamento.data).format('yyyy-MM-DD'),
      prazo: props.ordemServicoOrcamento.prazo,
      diasPrazo: diasExecucao,
      valor: valorOrcamento,
      observacoes: props.ordemServicoOrcamento.observacoes,
      ordemServicosOrcamentosItens: props.ordemServicoOrcamento.ordemServicosOrcamentosItens,
      idOrdemServico: idOrdemServico,
      possuiGarantia: props.ordemServicoOrcamento.possuiGarantia,
      observacoesGarantia: props.ordemServicoOrcamento.observacoesGarantia,
      ordensServicosOrcamentosAnexos: filesUploaded,
      idEmpresa: authActions.isEmpresa() ? props.authentication.user.usuariosEmpresas ? props.authentication.user.usuariosEmpresas[0].idEmpresa : valueEmpresa.id : valueEmpresa.id,
    }

    if (authActions.isGestor() || (authActions.isEmpresa() && verifyEmpresaAceitouTermos())) {
      if (params.id) {
        dispatch(ordemServicoOrcamentoAction.editOrdemServicoOrcamentoInfo(params.id, payload, true))
      } else {
        dispatch(ordemServicoOrcamentoAction.createOrdemServicoOrcamento(payload))
      }
    } else {
      setModalConfirmTermos(true)
    }
  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Orçamento'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Visualizar Orçamento'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Orçamento - Editar Orçamento'
          : 'Gerência de Orçamento - Novo Orçamento'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formOrdemServicoOrcamento"
                onSubmit={(event) => handleModalConfirm(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Orçamento"
                    title={<SegHeader />}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={6} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                  <strong>{params.id ? 'Seu orçamento para a ordem de serviço' : 'Ordem de serviço a ser orçada'}</strong>
                                </div>
                                <div>
                                  <CustomButton2 onClick={() => handleOpenModal()}>
                                    Ver fotos da ordem de serviço&nbsp;<AttachFileIcon />
                                  </CustomButton2>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(idOrdemServico && idOrdemServico != 0 && (params.id == 0 || params.id == undefined)) && props.ordemServico.ordemServico != null ?
                              props.ordemServico.ordemServico.map((n) => {
                                if (n.id == idOrdemServico) {
                                  return (
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                      <TableHead>
                                        <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}
                                          key={n.id}
                                        >
                                          <TableCell colSpan={6} component="th" scope="row" align="center">
                                            <strong>DETALHES DA ORDEM DE SERVIÇO</strong>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell><strong>Código</strong></TableCell>
                                          <TableCell><strong>Título</strong></TableCell>
                                          <TableCell><strong>Descritivo</strong></TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow hover>
                                          <TableCell># {n.id ? n.id : ''}</TableCell>
                                          <TableCell>{n.titulo ? n.titulo.toUpperCase() : ''}</TableCell>
                                          <TableCell>{n.descritivo ? n.descritivo.toUpperCase() : ''}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell><strong>Tipo de Serviço</strong></TableCell>
                                          <TableCell><strong>Veículo</strong></TableCell>
                                          <TableCell><strong>Data</strong></TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                          <TableCell>{n.tipoServico ? n.tipoServico.nome?.toUpperCase() : ''}</TableCell>
                                          <TableCell>{n.veiculo ? placaMask(n.veiculo.placa) : ''}</TableCell>
                                          <TableCell>{n.data ? moment(n.data).format("DD/MM/yyyy") : ''}</TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  )
                                }
                              })
                              : params.id == 0 &&
                              <TableRow
                                key={idOrdemServico}
                              >
                                <TableCell colSpan={6} component="th" scope="row" align="center">
                                  Esta ordem de servico não possui dados para exibir
                                </TableCell>
                              </TableRow>
                            }
                            {params.id != 0 && params.id != undefined && props.ordemServicoOrcamento.ordemServicoOrcamento ?
                              props.ordemServicoOrcamento.ordemServicoOrcamento.map((n) => {
                                if (n.idOrdemServico == idOrdemServico && n.id == params.id) {
                                  return (
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                      <TableHead>
                                        <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}
                                          key={n.id}
                                        >
                                          <TableCell colSpan={6} component="th" scope="row" align="center">
                                            <strong>DETALHES DA ORDEM DE SERVIÇO</strong>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell><strong>Código</strong></TableCell>
                                          <TableCell><strong>Título</strong></TableCell>
                                          <TableCell><strong>Descritivo</strong></TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow hover>
                                          <TableCell># {n.ordemServico.id ? n.ordemServico.id : ''}</TableCell>
                                          <TableCell>{n.ordemServico.titulo ? n.ordemServico.titulo.toUpperCase() : ''}</TableCell>
                                          <TableCell>{n.ordemServico.descritivo ? n.ordemServico.descritivo.toUpperCase() : ''}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell><strong>Tipo de Serviço</strong></TableCell>
                                          <TableCell><strong>Veículo</strong></TableCell>
                                          <TableCell><strong>Data</strong></TableCell>
                                        </TableRow>
                                        <TableRow hover>
                                          <TableCell>{n.ordemServico.tipoServico ? n.ordemServico.tipoServico.nome?.toUpperCase() : ''}</TableCell>
                                          <TableCell>{n.ordemServico.veiculo ? placaMask(n.ordemServico.veiculo.placa) : ''}</TableCell>
                                          <TableCell>{n.ordemServico.data ? moment(n.ordemServico.data).format("DD/MM/yyyy") : ''}</TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  )
                                }
                              })
                              : null
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                {idOrdemServico && idOrdemServico != 0 && (params.id == undefined || params.id == 0) ?
                  <>
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow size="small">
                              <TableCell colSpan={6} align="center"><strong>Elaborar orçamento</strong></TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            type="date"
                            disabled
                            id="data"
                            label="Data do Orçamento *"
                            margin="normal"
                            value={props.ordemServicoOrcamento.data ? moment(props.ordemServicoOrcamento.data).format("yyyy-MM-DD") : ''}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </Grid>
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            type="number"
                            id="diasPrazo"
                            label="Prazo de Execução (dias) *"
                            onChange={(e) => setDiasExecucao(e.target.value)}
                            margin="normal"
                            value={diasExecucao}
                            variant="outlined"
                            validators={['required']}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              min: 0
                            }}
                            errorMessages={['Campo obrigatório!']} />
                        </Grid>
                        {/*        se não for empresa, pede para selecionar*/}
                        {!authActions.isEmpresa() &&
                          <Grid item lg={3} md={3} xl={3} xs={12}>
                            <Autocomplete
                              value={valueEmpresa}
                              onChange={(event, newValue) => {
                                setValueEmpresa(newValue);
                              }}
                              style={{ marginTop: 16 }}
                              inputValue={inputValueEmpresa}
                              onInputChange={(event, newInputValue) => {
                                setInputValueEmpresa(newInputValue);
                              }}
                              id="controllable-states-demo"
                              options={optionsEmpresa}
                              isOptionEqualToValue={(option, value) => option.id == value.id}
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true
                              }}
                              defaultValue={valueEmpresa}
                              getOptionLabel={option => option.nome || ''}
                              renderInput={(params) => <TextField {...params} label="Selecione uma empresa..." fullWidth required />}
                              fullWidth />
                          </Grid>}
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="valor"
                            label="Valor do Orçamento"
                            margin="normal"
                            type="number"
                            InputProps={{
                              startAdornment: <Typography position="left">R$&nbsp;</Typography>
                            }}
                            inputProps={{
                              min: 0,
                              step: '0.1'
                            }}
                            onChange={(e) => setValorOrcamento(e.target.value)}
                            value={valorOrcamento ? valorOrcamento.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0 || 0}
                            variant="outlined" />
                        </Grid>
                        <Grid style={{ marginTop: 7 }} item lg={authActions.isEmpresa() ? 6 : 3} md={authActions.isEmpresa() ? 6 : 3} xl={authActions.isEmpresa() ? 6 : 3} xs={12} align="left">
                          <Backdrop className={classes.backdrop} open={open}>
                            <Grid
                              container
                              spacing={3}
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              alignContent="center"
                              wrap="nowrap"
                            >
                              <Grid item>
                                <CircularProgress color="primary" />
                              </Grid>
                              <Grid item>
                                <Typography variant="h4" color="textPrimary">
                                  Enviando arquivo...
                                </Typography>
                              </Grid>
                            </Grid>
                          </Backdrop>
                          <FormLabel style={{ fontSize: 12 }} component="legend">Arquivo (s)</FormLabel>
                          <Input
                            id="caminhoAnexo"
                            className={classes.textField}
                            label="Arquivo (s)"
                            onChange={e => handleUploadClickFiles(e)}
                            type="file"
                            variant="outlined"
                            inputProps={{ multiple: true }}  // Correct use of the 'multiple' attribute
                            placeholder="Enviar arquivo(s)"
                            formControl
                            margin="normal"
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            readOnly
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="observacoes"
                            label="Observações"
                            onChange={handleChange('observacoes')}
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            value={props.ordemServicoOrcamento.observacoes || ''}
                            variant="outlined" />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow size="small">
                              <TableCell colSpan={6} align="center"><strong>Detalhes da Garantia</strong></TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <FormControlLabel
                            control={<SwitchBlack
                              color="default"
                              checked={props.ordemServicoOrcamento.possuiGarantia}
                              onChange={handleChange('possuiGarantia')} />}
                            label="Possui garantia" />
                        </Grid>
                        <Grid item lg={10} md={10} xl={10} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="observacoesGarantia"
                            label="Observações"
                            onChange={handleChange('observacoesGarantia')}
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            value={props.ordemServicoOrcamento.observacoesGarantia || ''}
                            variant="outlined" />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </>
                  : params.id != 0 && params.id != undefined ?
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={6} align="center"><strong>Informações do Orçamento</strong></TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            type="date"
                            id="data"
                            label="Data"
                            margin="normal"
                            value={props.ordemServicoOrcamento.data ? moment(props.ordemServicoOrcamento.data).format("yyyy-MM-DD") : ''}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            readOnly
                          />
                        </Grid>
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            type="date"
                            id="prazo"
                            label="Prazo"
                            margin="normal"
                            value={props.ordemServicoOrcamento.prazo ? moment(props.ordemServicoOrcamento.prazo).format("yyyy-MM-DD") : ''}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            readOnly
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="empresa"
                            label="Empresa"
                            margin="normal"
                            value={props.ordemServicoOrcamento.empresa?.razaoSocial?.toUpperCase()}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            readOnly
                          />
                        </Grid>
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="valor"
                            label="Valor do Orçamento"
                            margin="normal"
                            InputProps={{
                              startAdornment: <Typography position="left">R$&nbsp;</Typography>
                            }}
                            value={props.ordemServicoOrcamento.valor ? props.ordemServicoOrcamento.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}
                            variant="outlined"
                            readOnly
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12} style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', flexDirection: 'column', marginTop: 6 }}>
                          <Typography style={{ color: '#666666' }} variant="caption" gutterBottom>Anexo(s)</Typography>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', flexDirection: 'row' }}>
                            {props.ordemServicoOrcamento.ordensServicosOrcamentosAnexos && Array.isArray(props.ordemServicoOrcamento.ordensServicosOrcamentosAnexos) &&
                              props.ordemServicoOrcamento.ordensServicosOrcamentosAnexos.length > 0 ? (
                              props.ordemServicoOrcamento.ordensServicosOrcamentosAnexos.map((a, index) => (
                                <Tooltip key={index} title={`Visualizar anexo ${index + 1}`}>
                                  <InsertDriveFileOutlinedIcon
                                    onClick={() => window.open(a.caminhoArquivo, '_blank')}
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '1.5em',
                                      marginLeft: 7 
                                    }}
                                  />
                                </Tooltip>
                              ))
                            ) : (
                              '-'
                            )}
                          </div>
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            readOnly
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="observacoes"
                            label="Observações"
                            margin="normal"
                            value={props.ordemServicoOrcamento?.observacoes?.toUpperCase() || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <CardContent lg={12} md={12} xl={12} xs={12}>
                        <Grid container spacing={2}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow size="small">
                                <TableCell colSpan={6} align="center"><strong>Detalhes da Garantia</strong></TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                          <Grid item lg={2} md={2} xl={2} xs={12}>
                            <Typography style={{ paddingTop: 6 }}>
                              {props.ordemServicoOrcamento.possuiGarantia ? 'Com Garantia' : 'Sem Garantia'}
                            </Typography>
                          </Grid>
                          <Grid item lg={10} md={10} xl={10} xs={12}>
                            <TextValidator
                              className={classes.textField}
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              readOnly
                              id="observacoesGarantia"
                              label="Observações"
                              multiLine={true}
                              rows={3}
                              margin="normal"
                              onInput={stringUtils.toInputUppercase}
                              value={props.ordemServicoOrcamento.observacoesGarantia || ''}
                              variant="outlined" />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardContent>
                    : null
                }
                <CardActions>
                  {(params.id == undefined || params.id == 0) ?
                    <>
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <Typography style={{ fontSize: 10, paddingLeft: 6 }}>
                            * Campos obrigatórios
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <Typography style={{ fontSize: 10, paddingLeft: 6 }}>
                            Atenção: Ao enviar o orçamento você se propõe e considerar o valor orçado até a data de previsão de início da ordem de serviço
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlgin: 'right',
                        justifyContent: 'right'
                      }}>
                        <CustomButton
                          variant="outlined"
                          onClick={params.id == undefined ? () => history.push('/ordensServicoAguardandoOrcamento') : () => history.push('/ordensServicoOrcamentos')}
                        >
                          Cancelar
                        </CustomButton>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          dark={true}
                          disabled={props.ordemServicoOrcamento.isLoading || ordemServicoOrcamento.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS}
                          startIcon={<SaveIcon />}
                          endIcon={props.ordemServicoOrcamento.isLoading && <CircularProgress size={24} />}
                        >
                          {params.id ? (props.ordemServicoOrcamento.isLoading ? 'Atualizando Orçamento...' : 'Atualizar') : (props.ordemServicoOrcamento.isLoading ? 'Salvando Orçamento...' : 'Salvar')}
                        </CustomButton>
                      </Grid>
                    </>
                    :
                    <Button
                      variant="outlined"
                      style={{ color: '#000', borderColor: '#000' }}
                      onClick={() => history.goBack()}
                    >
                      Voltar para tela anterior
                    </Button>
                  }
                </CardActions>
              </ValidatorForm>
            </Card >
          </Grid >
        </Grid >
      </Grid >
      {
        modalConfirm ?
          <Dialog
            open={modalConfirm}
            fullWidth
            style={{ padding: 0, margin: 0 }}
            maxWidth='xs'
            onClose={() => setModalConfirm(false)}
          >
            <DialogTitle>
              <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>
                CONFIRMAÇÃO DE ENVIO DE ORÇAMENTO
              </Typography>
              <Button
                variant="text"
                style={{ position: 'absolute', top: 0, right: 0, color: '#000', minWidth: 'unset' }}
                onClick={() => setModalConfirm(false)}>
                <CloseIcon />
              </Button>
            </DialogTitle>
            <DialogContent style={{ padding: 0, margin: 0 }}>
              <Card style={{ marginTop: 0, paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
                <Grid item display="flex" flexDirection="column" justifyContent="center">
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} >Ao enviar o orçamento você se compromete a começar a ordem de serviço no prazo estipulado.</CustomTypography>
                    </CustomGrid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} ><strong>Resumo do seu orçamento:</strong></CustomTypography>
                    </CustomGrid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} ><strong>Data: </strong>{moment(props.ordemServicoOrcamento.data).format('DD/MM/yyyy')}</CustomTypography>
                    </CustomGrid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} ><strong>Valor Total: </strong> R$ {valorOrcamento ? valorOrcamento.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}</CustomTypography>
                    </CustomGrid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12, }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} ><strong>Prazo de Entrega: </strong>{moment(props.ordemServicoOrcamento.prazo).format('DD/MM/yyyy')}</CustomTypography>
                    </CustomGrid>
                  </Grid>

                  <Button
                    onClick={(event) => handleSubmit(event)}
                    variant="contained"
                    style={{ backgroundColor: '#000', color: '#FFF', marginTop: 16 }}
                    disabled={props.ordemServicoOrcamento.isLoading || ordemServicoOrcamento.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS}
                    startIcon={<SaveIcon />}
                    endIcon={props.ordemServicoOrcamento.isLoading && <CircularProgress size={24} />}
                  >
                    {params.id ? (props.ordemServicoOrcamento.isLoading ? 'Atualizando Orçamento...' : 'Atualizar') : (props.ordemServicoOrcamento.isLoading ? 'Salvando Orçamento...' : 'Enviar Orçamento')}
                  </Button>
                </Grid>
              </Card>
            </DialogContent>
          </Dialog >
          : null
      }
      {
        (modal ?
          <OrdemServicoOrcamentoAnexoEmpresaModal toggle={toggle} modal={modal} props={props} idOrdemServico={idOrdemServico} />
          :
          null
        )
      }
      {
        modalConfirmTermos ?
          <EmpresaAceiteTermosModal
            toggle={toggleAceiteTermos}
            modal={modalConfirmTermos}
            props={props}
          />

          : null
      }
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.CADASTRO_ORCAMENTO}
          />
          :
          null
        )
      }
    </Page >
  )
}

OrdemServicoOrcamentoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedOrdemServicoOrcamentoEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdemServicoOrcamentoEdit))
)
export { connectedOrdemServicoOrcamentoEditPage as OrdemServicoOrcamentoEdit }
