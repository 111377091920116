import { crudService } from '../_services/'
import { stringUtils } from 'utils'
import { history } from '../utils'
import { toast } from 'react-toastify'

export const empresaAction = {
  getEmpresa,
  getEmpresaSelect,
  addEmpresa,
  getEmpresaById,
  onChangeProps,
  editEmpresaInfo,
  editAprovaCadastroEmpresa,
  editRecusaCadastroEmpresa,
  editEmpresasDetails,
  editEmpresaAceitaTermosUso,
  createEmpresa,
  createEmpresaApp,
  changeDetailsEmpresa,
  deleteEmpresaById,
  clear,
  clearAll,
  clearUsuariosEmpresas,
  clearTiposVeiculos,
  clearTiposServicos,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getEmpresa(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empresas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeEmpresasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getEmpresaSelect() {
  return (dispatch) => {
    let apiEndpoint = 'empresas/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeEmpresasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createEmpresa(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empresas/'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(createEmpresaInfo())
          toast.success('Empresa cadastrada com sucesso!')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/empresas')
        }
      })
      .catch((err) => {
        dispatch(notIsLoading())
      })
  }
}

function createEmpresaApp(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empresas/empresaApp'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(createEmpresaInfo())
          toast.success('Empresa cadastrada com sucesso!')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/login')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(notIsLoading())
      })
  }
}

function editEmpresaAceitaTermosUso(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empresas/aceiteTermos/' + id
    crudService
      .putWithNoToast(apiEndpoint)
      .then((response) => {
        toast.success('Termos de uso aceitos com sucesso')
        dispatch(notIsLoading())
        dispatch(getEmpresaSelect())
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_EMPRESA_FAILURE', error }
  }
}

function editAprovaCadastroEmpresa(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empresas/aprova/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/empresas')
        } else {
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORGAO_FAILURE', error }
  }
}

function editRecusaCadastroEmpresa(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empresas/recusa/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/empresas')
        } else {
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }
}

function getEmpresaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'empresas/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editEmpresasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}
function changeDetailsEmpresa(empresa) {
  return (dispatch) => {
    if (empresa) {
      dispatch(onChangeDetailsEmpresa(empresa))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsEmpresa(empresa) {
  return [
    {
      type: 'CHANGE_DETAILS_EMPRESA',
      id: empresa.id,
      nome: empresa.nome
    }
  ]
}

function editEmpresaInfo(id, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empresas/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/empresas')
        } else {
          dispatch(notIsLoading())
        }
      }).catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }
}

function deleteEmpresaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'empresas/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteEmpresasDetails())
      dispatch(getEmpresa(filterModel))
      history.push('/empresas')
    })
  }
}

export function addEmpresa(payload) {
  return [{ type: 'ADD_EMPRESA', empresa: payload }, clear()]
}

export function changeEmpresasList(empresa, totalRows) {
  return {
    type: 'FETCHED_ALL_EMPRESA',
    empresa: empresa,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'EMPRESA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'EMPRESA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'EMPRESA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editEmpresasDetails(empresa) {
  return {
    type: 'EMPRESA_DETAIL',
    id: empresa.id,
    razaoSocial: empresa.razaoSocial,
    nomeFantasia: empresa.nomeFantasia,
    cnpj: empresa.cnpj,
    cpf: empresa.cpf,
    telefone: empresa.telefone,
    celular: empresa.celular,
    email: empresa.email,
    endereco: empresa.endereco,
    inscricaoEstadual: empresa.inscricaoEstadual,
    idCidade: empresa.idCidade,
    idEstado: empresa.idEstado,
    usuariosEmpresas: empresa.usuariosEmpresas,
    empresasAnexos: empresa.empresasAnexos,
    empresasOrgaos: empresa.empresasOrgaos,
    empresasTiposServicos: empresa.empresasTiposServicos,
    empresasTiposVeiculos: empresa.empresasTiposVeiculos,
    informacoesCobranca: empresa.informacoesCobranca,
    fornecedor: empresa.fornecedor,
    autonomo: empresa.autonomo,
    cidade: empresa.cidade,
    prestadorServico: empresa.prestadorServico,
    cadastroLiberado: empresa.cadastroLiberado,
    aceiteTermos: empresa.aceiteTermos,
    mediaNota: empresa.mediaNota,
    totalAvaliacoes: empresa.totalAvaliacoes
  }
}

export function updatedEmpresaInfo() {
  return {
    type: 'EMPRESA_UPDATED'
  }
}

function failure(error) {
  return { type: 'EDIT_EMPRESA_FAILURE', error }
}

export function createEmpresaInfo() {
  return {
    type: 'EMPRESA_CREATED_SUCCESSFULLY'
  }
}

export function deleteEmpresasDetails() {
  return {
    type: 'DELETED_EMPRESA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'EMPRESA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'EMPRESA_NOTISLOADING'
  }
}

export function clearUsuariosEmpresas() {
  return {
    type: 'EMPRESA_CLEAR_USUARIOS_EMPRESAS'
  }
}

export function clearTiposVeiculos() {
  return {
    type: 'EMPRESA_CLEAR_TIPOS_VEICULOS'
  }
}

export function clearTiposServicos() {
  return {
    type: 'EMPRESA_CLEAR_TIPOS_SERVICOS'
  }
}
