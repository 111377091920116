import React, { useLayoutEffect, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help';
import {
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
  Typography,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import messageTime from 'utils/stringTimeHello'
import { Page } from 'components'
import { withRouter } from 'react-router-dom'
import { MANUAIS_CATEGORIAS } from '../../utils'
import { authActions, dashboardAction, empresaAction } from '../../_actions'
import CardOrdemServico from '../Dashboard/components/CardOrdemServico'
import GraficoOrdensServicoStatusChartCard from '../Dashboard/components/GraficoOrdensServicoStatusChartCard'
import GraficoOrdensServicoPorMesChartCard from '../Dashboard/components/GraficoOrdensServicoPorMesChartCard'
import CardOrdemServicoPendenteVerificacao from '../Dashboard/components/CardOrdemServicoPendentesVerificacao'
import CardOrdemServicoRecebendoOrcamentos from './components/CardOrdemServicoRecebendoOrcamentos'
import CardInfosValues from './components/CardInfosValues'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { LocalAtmOutlined, PriceCheck } from '@mui/icons-material';
import { EmpresaAceiteTermosModal } from 'views/Empresas/empresaAceiteTermosModal.component'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    textAlign: 'center'
  },
  gridCards: {
    //alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  warningTermos: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    cursor: 'pointer'
  }
});

const Dashboard = (props) => {

  const { className, classes, ...rest } = props
  const { match: { params } } = props;
  const dispatch = useDispatch();
  const [modalConfirmTermos, setModalConfirmTermos] = useState(false);

  const toggleAceiteTermos = () => setModalConfirmTermos(!modalConfirmTermos);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const user = useSelector((state) => state.authentication.user)

  const isLoading = useSelector((state) =>
    state.dashboard.isLoading)

  useLayoutEffect(() => {
    dispatch(authActions.refresh())
  }, [])

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { empresa } = useSelector(
    (state) => state.empresa
  )

  useEffect(() => {

    let filterModel = {
      IdOrgao: idOrgao
    };

    dispatch(dashboardAction.getDashboard(filterModel));
    dispatch(empresaAction.getEmpresaSelect())
  }, [])

  const verifyEmpresaAceitouTermos = () => {
    if (props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa) {
      return empresa?.some(em => props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa == parseInt(em.value) && em.excluido != true && em.aceiteTermos == true);
    }
    return false;
  }

  useEffect(() => {

    let filterModel = {
      IdOrgao: idOrgao
    };

    dispatch(dashboardAction.getDashboard(filterModel));
  }, [idOrgao])

  return (
    <Page
      className={classes.root}
      title='Rotor - Dashboard'
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Grid alignItems="center" container justifyContent="center" spacing={3}>
          <Grid item lg={11} sm={11} xl={11} xs={12}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography component="h1" variant="h5">
                {messageTime()}, {user ? user.nome : ''}
              </Typography>
              {authActions.isGestor() ?
                <Grid item lg={1} sm={1} xl={1} xs={12}>
                  <IconButton
                    aria-label="Ajuda"
                    component="a"
                    onClick={() =>
                      handleOpenModalHelp()
                    }
                    size="small"
                  >
                    <Tooltip title={'Ajuda'}>
                      <Tooltip>
                        <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                      </Tooltip>
                    </Tooltip>
                  </IconButton>
                </Grid>
                : null}
            </div>
            <br></br>
            <Typography component="h2" gutterBottom variant="h6">
              {'Seja bem vindo'}
            </Typography>
          </Grid>
          <Grid container className={classes.gridCards} spacing={2}>
            <Grid container spacing={1}>
              {!authActions.isFiscal() && !authActions.isEmpresa() ?
                <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                  <CardOrdemServico />
                </Grid>
                : authActions.isFiscal() ?
                  <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                    <CardOrdemServicoPendenteVerificacao />
                  </Grid>
                  :
                  authActions.isEmpresa() && verifyEmpresaAceitouTermos() == false &&
                  <Grid item lg={12} sm={12} xl={12} md={12} xs={12} className={classes.warningTermos} onClick={toggleAceiteTermos}>
                    <Alert severity="warning">
                      <AlertTitle>
                        <strong>
                          Termos de Uso do Sistema
                        </strong>
                      </AlertTitle>
                      Você precisa aceitar os termos de uso para seguir usando o sistema. Clique aqui para ler o documento e aceitar
                    </Alert>
                  </Grid>
              }
              {authActions.isEmpresa() &&
                <Grid item lg={4} sm={4} xl={4} md={6} xs={12}>
                  <CardOrdemServicoRecebendoOrcamentos />
                </Grid>
              }
              {(authActions.isGestor() || authActions.isPrefeitura()) &&
                <>
                  <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                    <CardInfosValues
                      indicator="valorTotalEmpenhosEmAberto"
                      text="Valor de empenhos em aberto"
                      icon={<LocalAtmOutlined />}
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                    <CardInfosValues
                      indicator="valorTotalEmpenhosPagos"
                      text="Valor de empenhos pagos"
                      icon={<PriceCheck />}
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                    <CardInfosValues
                      indicator="valorTotalEmpenhos"
                      text="Valor total de empenhos"
                      icon={<MonetizationOnIcon />}
                    />
                  </Grid>
                </>
              }
            </Grid>
            {(authActions.isGestor() || authActions.isPrefeitura()) &&
              <Grid container className={classes.gridCards} spacing={3}>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                  <GraficoOrdensServicoStatusChartCard />
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                  <GraficoOrdensServicoPorMesChartCard />
                </Grid>
              </Grid>
            }
          </Grid>
          <Backdrop className={classes.backdrop} open={isLoading}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
            >
              <Grid item>
                <CircularProgress sx={{ color: '#000' }} />
              </Grid>
              <Grid item>
                <Typography variant="h5" color="textPrimary">
                  Carregando dados...
                </Typography>
              </Grid>
            </Grid>
          </Backdrop>
          {modalConfirmTermos ?
            <EmpresaAceiteTermosModal
              toggle={toggleAceiteTermos}
              modal={modalConfirmTermos}
              props={props}
            />
            : null
          }
        </Grid>
      </div>
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.DASHBOARD}
          />
          :
          null
        )
      }
    </Page >
  )
}
Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedDashboardPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Dashboard))
)
export { connectedDashboardPage as Dashboard }
