import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ordemServicoCobrancaAction, authActions } from '../../_actions'
import PropTypes from 'prop-types'
import moment from 'moment'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Skeleton,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { useConfirm } from 'material-ui-confirm'
import { withRouter } from 'react-router-dom'
import CircleIcon from '@mui/icons-material/Circle';
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import TitleContent from '../TitleContent'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const OrdemServicoCobranca = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const { classes, className } = props
  const { ordemServicoCobranca } = props.ordemServicoCobranca
  const { totalRegistros } = props.ordemServicoCobranca
  const { isLoading } = props.ordemServicoCobranca
  const isCobranca = (authActions.isGestor() || authActions.isPrefeitura() || authActions.isEmpresa()) ? true : false;

  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');
  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: status
      };

      dispatch(ordemServicoCobrancaAction.getOrdemServicoCobranca(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idOrgao, status, term]);

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {

  }, [ready, props.ordemServicoCobranca.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  const handleChangeRadio = prop => event => {
    setStatus(event.target.value);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta cobrança?',
      description: 'Essa operação é irreversível',
      disabled: ordemServicoCobranca.isLoading,
      confirmationText: ordemServicoCobranca.isLoading ? 'Excluindo cobrança...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(ordemServicoCobrancaAction.deleteOrdemServicoCobrancaById(id, idOrgao))
    })
  }

  return (
    <Page className={classes.root} title="Ordens Serviços Cobranças">
      <AccessControl
        rule={'ordensServicosCobrancas.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <TitleContent
              length={ordemServicoCobranca?.length}
              subTitle={'GERÊNCIA DE COBRANÇAS'}
              telaManual={MANUAIS_CATEGORIAS.LISTAGEM_COBRANCA}
              title={'Lista de Cobranças'}
            />
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              <Grid item xl={9} lg={9} md={9} xs={12} align="right">
                <FormControl>
                  <FormLabel>
                    <Typography variant="subtitle1">Filtrar por Status </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={handleChangeRadio('status')}
                    value={status}
                  >
                    <FormControlLabel value="0" control={<Radio size="sm" />} label={<Typography variant="subtitle2">Em Aberto</Typography>} />
                    <FormControlLabel value="1" control={<Radio size="sm" />} label={<Typography variant="subtitle2">Pago</Typography>} />
                    <FormControlLabel value="2" control={<Radio size="sm" />} label={<Typography variant="subtitle2">Todas</Typography>} />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Empresa</TableCell>
                    <TableCell>Órgão</TableCell>
                    <TableCell align="left">Ordem de Serviço</TableCell>
                    <TableCell align="center">Valor</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== ordemServicoCobranca && ordemServicoCobranca.length
                    ? ordemServicoCobranca.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n ? moment(n.dataCobranca).format("DD/MM/YYYY") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.empresa ? n.ordemServico.orgao?.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">
                            {n.ordemServico ? `#${n.ordemServico.id} ${n.ordemServico?.titulo.toUpperCase()}` : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">
                            <Tooltip
                              title={n.dataPagamento != null ? `Pago em ${moment(n.dataPagamento).format("DD/MM/YYYY HH:mm")}` : false}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <CircleIcon sx={{ color: n.dataPagamento == null ? '#cf0d0d' : '#0d6e0d', fontSize: 12, marginRight: 1 }} /> <span>{n.dataPagamento == null ? 'EM ABERTO' : 'PAGO'}</span></div>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">
                            <AccessControl
                              rule={'ordensServicosCobrancas.list'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Visualizar"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/ordemServico/${n.idOrdemServico}`, { isCobranca, empresa: n.empresa })
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Ver detalhes'}>
                                    <Tooltip >
                                      <VisibilityIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'ordensServicosCobrancas.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
OrdemServicoCobranca.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedOrdemServicoCobrancaPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdemServicoCobranca))
)
export { connectedOrdemServicoCobrancaPage as OrdemServicoCobranca }
