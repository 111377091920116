import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, tipoServicoAction } from '../../_actions'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment'
import TitleContentInformation from '../TitleContentInformation'
import HelpIcon from '@mui/icons-material/Help';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  IconButton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { cnpjMask, stringUtils, placaMask, MANUAIS_CATEGORIAS } from '../../utils'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const RelatorioServicoRealizado = (props) => {

  const dispatch = useDispatch()
  const isFirstRender = useRef(true);
  const { classes, className, ...rest } = props
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [razaoSocial, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');
  //const [mecanicoDelivery, setMecanicoDelivery] = useState(false);
  const [valueTipoServico, setValueTipoServico] = useState(null);
  const [placaVeiculo, setPlacaVeiculo] = useState(null);
  const [inputValueTipoServico, setInputValueTipoServico] = useState('');
  const [openTipoServico, setOpenTipoServico] = useState(false)
  const [idTipoServico, setIdTipoServico] = useState(0)

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [ready, setReady] = React.useState(false);

  const { tipoServico } = useSelector(
    (state) => state.tipoServico)

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  let filterModel = {
    IdTipoServico: valueTipoServico ? valueTipoServico.id : 0,
    RazaoSocial: razaoSocial,
    Cnpj: cnpj ? cnpj.split('.').join('').replace(/[-/.]/g, '') : '',
    PlacaVeiculo: placaVeiculo ? placaVeiculo.split('-').join('').replace('-', '') : '',
    IdOrgao: idOrgao, 
    Page: page,
    Limit: limit
  };

  useEffect(() => {
    dispatch(tipoServicoAction.getTiposServicoSelect());
    dispatch(relatorioAction.clear());
  }, []);

  const optionsTiposServicos = tipoServico && tipoServico.map(u => ({ id: u.value, nome: u.text }));

  const handlePrint = () => {
    const queryParams = new URLSearchParams(filterModel).toString();
    if (queryParams) {
      window.open(`/relatorioServicosRealizadosPrint?${queryParams}`, '_blank');
    }
  }

  const handleExportExcel = () => {

    const modifiedFilterModel = {
      ...filterModel,
      Limit: 9999999
    };

    dispatch(relatorioAction.getRelatorioServicosRealizadosArquivo(modifiedFilterModel));
  }

  const handleSubmit = (event) => {
    dispatch(relatorioAction.getRelatorioServicosRealizados(filterModel, true));
  }

  useEffect(() => {
    // se é a primeira renderização, não faz a chamada
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    dispatch(relatorioAction.getRelatorioServicosRealizados(filterModel, true));
  }, [page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (valueTipoServico) {
      handleChangeAutoCompleteTipoServico(valueTipoServico)
    }
  }, [valueTipoServico])

  const handleChangeAutoCompleteTipoServico = (obj) => {
    if (obj != null) {
      let selectedTípoServico = tipoServico.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        tipoServicoAction.changeDetailsTipoServico(
          selectedTípoServico[0]
        )
      )
    } else {

    }
    setOpenTipoServico(false)
  }

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.relatorio.isLoading])

  return (
    <Page className={classes.root} title="Relatório - Serviços Realizados">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorio"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                      <TitleContentInformation
                        subTitle={'RELATÓRIO'}
                        title={'Relatório de Serviços Realizados'}
                      />
                      <Grid item lg={1} md={1} xl={1} xs={12}>
                        <IconButton
                          aria-label="Ajuda"
                          component="a"
                          onClick={() =>
                            handleOpenModalHelp()
                          }
                          size="small"
                        >
                          <Tooltip title={'Ajuda'}>
                            <Tooltip>
                              <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                            </Tooltip>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={5} md={5} xl={5} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueTipoServico}
                          onChange={(event, newValue) => {
                            setValueTipoServico(newValue);
                          }}
                          inputValue={inputValueTipoServico}
                          onInputChange={(event, newInputValueTipoServico) => {
                            setInputValueTipoServico(newInputValueTipoServico);
                          }}
                          id="controllable-states-demo"
                          options={optionsTiposServicos}
                          margin="normal"
                          disabled={(idTipoServico != null && idTipoServico != 0 && idTipoServico != '')}
                          variant="outlined"
                          defaultValue={valueTipoServico}
                          getOptionLabel={(optionTipoServico) => (optionTipoServico ? optionTipoServico.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Tipo de Serviço... " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="razaoSocial"
                          label="Razão Social"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={(e) => setRazaoSocial(e.target.value)}
                          value={razaoSocial || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="cnpj"
                          label="CNPJ"
                          margin="normal"
                          onChange={(e) => setCnpj(e.target.value)}
                          value={cnpjMask(cnpj) || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={5} md={5} xl={5} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="placaVeiculo"
                          label="Placa Veículo"
                          margin="normal"
                          onChange={(e) => setPlacaVeiculo(e.target.value)}
                          value={placaMask(placaVeiculo) || ''}
                          variant="outlined"
                        />
                      </Grid>
                      {/*<Grid item lg={4} md={4} xl={4} xs={12} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>*/}
                      {/*  <FormControlLabel*/}
                      {/*    control={*/}
                      {/*      <SwitchStyled*/}
                      {/*        color="default"*/}
                      {/*        checked={mecanicoDelivery}*/}
                      {/*        onChange={() => setMecanicoDelivery(!mecanicoDelivery)}*/}
                      {/*      />*/}
                      {/*    }*/}
                      {/*    label="Mecânico Delivery"*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <CustomButton
                            type="submit"
                            variant="contained"
                            dark={true}
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFilePdf} style={{ verticalAlign: 'middle' }} />}
                          >
                            Gerar PDF
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="button"
                            onClick={() => handleExportExcel()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
                          >
                            Exportar para Excel
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>DATA</strong></TableCell>
                        <TableCell><strong>SERVIÇO REALIZADO</strong></TableCell>
                        <TableCell><strong>TIPO DE SERVIÇO</strong></TableCell>
                        <TableCell><strong>RAZÃO SOCIAL</strong></TableCell>
                        <TableCell><strong>VEÍCULO</strong></TableCell>
                        <TableCell align="right"><strong>VALOR</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {relatorio && undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.dataOrdemServico ? moment(n.dataOrdemServico).format("DD/MM/yyyy") : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                # {n.idOrdemServico} {n.tituloOrdemServico ? n.tituloOrdemServico.toUpperCase() : ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.tipoServico ? n.tipoServico.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.nomeEmpresa ? n.nomeEmpresa.toUpperCase() : ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.placaVeiculo ? placaMask(n.placaVeiculo) : ''}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={totalRegistros}
                    page={page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeLimit}
                  />
                </TableContainer>
              </Card>
            </Grid>
            {
              (modalHelp ?
                <HelpModal
                  toggle={toggleModalHelp}
                  modal={modalHelp}
                  props={props}
                  tela={MANUAIS_CATEGORIAS.RELATORIO_SERVICO_REALIZADO}
                />
                :
                null
              )
            }
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
RelatorioServicoRealizado.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioServicoRealizadoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(RelatorioServicoRealizado))
)
export { connectedRelatorioServicoRealizadoPage as RelatorioServicoRealizado }
