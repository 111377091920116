import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { authActions, ordemServicoAction } from '../../_actions'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import PropTypes from 'prop-types'
import moment from 'moment'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { placaMask } from '../../utils'
import {
  Grid,
  Paper,
  Typography,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { styled } from '@mui/material/styles';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
}));

const CustomGrid2 = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 4
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: -10,
    marginBottom: -8,
    paddingTop: -10,
    paddingBottom: -8,
    paddingRight: 3,
    marginRight: 3,
    paddingLeft: 3,
    marginLeft: 3
  },
}));

const OrdemServicoSummaryCard = (props) => {

  const { match: { params } } = props;
  const { idOrdemServico } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ordemServicoAction.clear())
    if (idOrdemServico) {
      dispatch(ordemServicoAction.getOrdemServicoById(idOrdemServico))
    }
  }, [])
  function isWinner(ordemServico) {
    const idEmpresa = authActions.isEmpresa() ? props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa : 0

    return ordemServico.ordensServicosOrcamentos?.some(orcamento => orcamento.idEmpresa === idEmpresa && orcamento.statusOrcamento === 1);
  }

  const empenhos = useSelector((state) => state.ordemServico?.ordensServicosEmpenhos)

  const verifyObraHasEmpenho = () => {
    return empenhos?.some(ts => ts.idOrdemServico == parseInt(idOrdemServico) && ts.excluido != true);
  }

  const returnLinkEmpenhoArchive = () => {
    return empenhos?.find(ts => ts.idOrdemServico == parseInt(idOrdemServico) && ts.excluido != true)?.empenho?.caminhoArquivo;
  }

  return (
    <Grid item lg={12} md={12} xl={12} xs={12}>
      {idOrdemServico &&
        <Paper elevation={3} style={{ marginBottom: 6, backgroundColor: '#f7f7f7', paddingBottom: 4 }}>
          <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <CustomGrid item xl={12} lg={12} md={12} xs={12} align="center">
              {
                authActions.isEmpresa() ?
                  isWinner(props.ordemServico) ?
                    <span style={{ backgroundColor: '#00cc66', borderRadius: 8, padding: 4, color: '#fff', fontSize: 16, marginRight: 4, fontWeight: 400 }}>
                      <WarningAmberOutlinedIcon style={{ verticalAlign: 'middle', marginRight: 2, fontSize: 'medium' }} /> VOCÊ É A EMPRESA VENCEDORA DESTA ORDEM DE SERVIÇO
                    </span>
                    :
                    <span style={{ backgroundColor: '#ef5350', borderRadius: 8, padding: 4, color: '#fff', fontSize: 16, marginRight: 4, fontWeight: 400 }}>
                      <WarningAmberOutlinedIcon style={{ verticalAlign: 'middle', marginRight: 2, fontSize: 'medium' }} /> VOCÊ NÃO É A EMPRESA VENCEDORA DESTA ORDEM DE SERVIÇO
                    </span>
                  :
                  null
              }


            </CustomGrid>
            <CustomGrid item xl={8} lg={8} md={8} xs={12} align="left">
              <CustomTypography variant="overline" style={{ fontSize: 15 }} ><strong>RESUMO DA ORDEM DE SERVIÇO</strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={4} lg={4} md={4} xs={12} align="right">
              <CustomTypography variant="overline"><strong>DATA: </strong>{props.ordemServico.data ? moment(props.ordemServico.data).format("DD/MM/yyyy") : ''}</CustomTypography>
            </CustomGrid>
          </Grid>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>TÍTULO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">#{props.ordemServico?.id} {props.ordemServico?.titulo}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>DESCRITIVO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">{props.ordemServico?.descritivo}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>VEÍCULO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">
                {props.ordemServico?.veiculo?.marca?.nome} / {props.ordemServico?.veiculo?.modelo}&nbsp;&nbsp;
                {props.ordemServico?.veiculo?.ano} - {placaMask(props.ordemServico?.veiculo?.placa)} /&nbsp;
                <span style={{ fontStyle: 'italic', color: '#555' }}>
                  Chassi: {props.ordemServico?.veiculo?.numeroChassis ? props.ordemServico?.veiculo?.numeroChassis : 'Não informado'}
                </span>
              </CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>TIPO DE SERVIÇO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">{props.ordemServico?.tipoServico?.nome}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>ÓRGÃO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="left">
              <CustomTypography variant="overline">{props.ordemServico.orgao?.nome?.toUpperCase()}</CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="right">
              <CustomTypography variant="overline" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                <strong>Possui empenho:&nbsp;</strong>
                {verifyObraHasEmpenho() ? (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    SIM
                    {!authActions.isEmpresa() &&
                      <Tooltip title={'Abrir arquivo do empenho em nova aba'}>
                        <OpenInNewOutlinedIcon
                          onClick={() => window.open(returnLinkEmpenhoArchive(), '_blank')}
                          style={{
                            cursor: 'pointer',
                            marginLeft: '5px',
                            fontSize: '1.5em'
                          }}
                        />
                      </Tooltip>
                    }
                  </span>
                ) : (
                  'NÃO'
                )}
              </CustomTypography>

            </CustomGrid>
          </CustomGrid2>
        </Paper>
      }
    </Grid>
  )
}

OrdemServicoSummaryCard.propTypes = {
  classes: PropTypes.object.isRequired,
  idOrdemServico: PropTypes.number.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const connectedOrdemServicoSummaryCardPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdemServicoSummaryCard))
)
export { connectedOrdemServicoSummaryCardPage as OrdemServicoSummaryCard }
