import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { authActions, ordemServicoAction, tipoServicoAction, tipoVeiculoAction, estadoAction } from '../../_actions'
import PropTypes from 'prop-types'
import CircleIcon from '@mui/icons-material/Circle';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import moment from 'moment'
import {
  Button,
  ButtonGroup,
  Grid,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Paper,
  Tooltip,
  Skeleton,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { STATUS_SERVICO, history, getNomeUltimoEventoFormatadoLista, getCor, MANUAIS_CATEGORIAS } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';
import { OrdemServicoMotivoModal } from '../../views/OrdensServico'

const ButtonFilter = styled(Button)(({ selected }) => ({
  color: '#000',
  backgroundColor: selected ? '#FFC107' : 'transparent',
  border: '1px solid #000',
  '&:hover': {
    backgroundColor: '#FFC107',
    border: '1px solid #000',
  },
  '&&:focus': {
    border: '1px solid #000',
    backgroundColor: '#FFC107',
    transition: '0.3s',
  },
}));

const CustomButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    textAlign: 'center',
    justifyContent: 'end',
    color: '#000'
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  justifyContent: 'right',
  textAlign: 'right',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    textAlign: 'center'
  },
}));

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const OrdemServico = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, className } = props
  const { ordemServico } = props.ordemServico
  const { totalRegistros } = props.ordemServico
  const { isLoading } = props.ordemServico

  const [status, setStatus] = useState('8');
  const [typeVehicle, setTypeVehicle] = useState('');
  const [typeService, setTypeService] = useState('');
  const [state, setState] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');
  const [ready, setReady] = React.useState(false);
  const [idOrdemServico, setIdOrdemServico] = useState(0);

  const [modalMotivo, setModalMotivo] = useState(false);
  const toggleModalMotivo = () => setModalMotivo(!modalMotivo);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { tipoServico } = useSelector(
    (state) => state.tipoServico
  )

  const { tipoVeiculo } = useSelector(
    (state) => state.tipoVeiculo
  )

  const { estado } = useSelector(
    (state) => state.estado
  )
  function isWinner(ordemServico) {
    const idEmpresa = authActions.isEmpresa() ? props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa : 0
    return ordemServico.ordensServicosOrcamentos?.some(orcamento => orcamento.idEmpresa === idEmpresa && orcamento.statusOrcamento === 1);
  }

  const handleOpenModalMotivo = (idOs) => {
    setIdOrdemServico(idOs)
    toggleModalMotivo();
  }

  useEffect(() => {
    dispatch(tipoServicoAction.getTiposServicoSelect())
    dispatch(tipoVeiculoAction.getTipoVeiculoSelect())
    dispatch(estadoAction.getEstadoSelect())
  }, [])

  const buttons = [
    <ButtonFilter key="0" size="small" variant="outlined" selected={status == ''} onClick={() => setStatus('')}>Em lançamento</ButtonFilter>,
    <ButtonFilter key="1" size="small" variant="outlined" selected={status == '1'} onClick={() => setStatus('1')}>Pendentes</ButtonFilter>,
    <ButtonFilter key="2" size="small" variant="outlined" selected={status == '2'} onClick={() => setStatus('2')}>Recebendo Orçamentos</ButtonFilter>,
    <ButtonFilter key="3" size="small" variant="outlined" selected={status == '3'} onClick={() => setStatus('3')}>Em execução</ButtonFilter>,
    <ButtonFilter key="4" size="small" variant="outlined" selected={status == '4'} onClick={() => setStatus('4')}>Recusadas</ButtonFilter>,
    <ButtonFilter key="5" size="small" variant="outlined" selected={status == '5'} onClick={() => setStatus('5')}>Concluídas</ButtonFilter>,
    <ButtonFilter key="6" size="small" variant="outlined" selected={status == '7'} onClick={() => setStatus('7')}>Verificadas</ButtonFilter>,
    <ButtonFilter key="7" size="small" variant="outlined" selected={status == '6'} onClick={() => setStatus('6')}>Avaliadas</ButtonFilter>,
    <ButtonFilter key="8" size="small" variant="outlined" selected={status == '8'} onClick={() => setStatus('8')}>Todas</ButtonFilter>
  ];

  const buttonsVehicleType = tipoVeiculo && tipoVeiculo.length > 0 && tipoVeiculo.map(t =>
    <ButtonFilter key={t.value} size="small" variant="outlined" selected={typeVehicle == t.value} onClick={() => setTypeVehicle(t.value)}>{t.text}</ButtonFilter>,
  )

  const buttonsServiceType = tipoServico && tipoServico.length > 0 && tipoServico.map(t =>
    <ButtonFilter key={t.value} size="small" variant="outlined" selected={typeService == t.value} onClick={() => setTypeService(t.value)}>{t.text}</ButtonFilter>,
  )

  const buttonsState = estado && estado.length > 0 && estado.map(t =>
    <ButtonFilter key={t.value} size="small" variant="outlined" selected={state == t.value} onClick={() => setState(t.value)}>{t.text}</ButtonFilter>,
  )

  useEffect(() => {
    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: status == STATUS_SERVICO.AVALIADO ? 'dataMudancaSituacao' : order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: status,
        TiposVeiculos: typeVehicle,
        TiposServicos: typeService,
        Estados: state
      };
      dispatch(ordemServicoAction.getOrdemServico(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [status, typeVehicle, page, limit, debounceTerm, order, direction, idOrgao, typeService, state])

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.ordemServico.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Ordens de Serviço">
      <AccessControl
        rule={'ordensServico.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== ordemServico && ordemServico.length > 0 ? (
              <TitleContent
                rule={'ordensServico.create'}
                length={ordemServico.length}
                subTitle={'GERÊNCIA DE ORDEM DE SERVIÇO'}
                title={'Lista de Ordem de Serviço'}
                href={'/ordemServico'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_ORDEM_SERVICO}
              />
            ) : (
              <TitleContent
                rule={'ordensServico.create'}
                length={0}
                subTitle={'GERÊNCIA DE ORDEM DE SERVIÇO'}
                title={'Lista de Ordem de Serviço'}
                href={'/ordemServico'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_ORDEM_SERVICO}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              <>
                <Grid item xl={12} lg={12} md={12} xs={12} align="right">
                  <FormLabel>
                    <CustomTypography variant="h6">Filtros</CustomTypography>
                  </FormLabel>
                  <FormControl>
                    <FormLabel>
                      <CustomTypography variant="subtitle1">Status</CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttons}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} align="right">
                  <FormControl>
                    <FormLabel>
                      <CustomTypography variant="subtitle1">Tipo de Veículo</CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttonsVehicleType}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} align="right">
                  <FormControl>
                    <FormLabel>
                      <CustomTypography variant="subtitle1">Tipo de Serviço</CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttonsServiceType}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} align="right">
                  <FormControl>
                    <FormLabel>
                      <CustomTypography variant="subtitle1">Estado</CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttonsState}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
              </>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Tipo de Serviço</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell>Órgão</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== ordemServico && ordemServico.length
                    ? ordemServico.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.id ? n.id : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.tipoServico ? n.tipoServico.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              {n.emergencia ? (
                                <Tooltip title="Ordem de serviço emergencial">
                                  <WarningAmberIcon sx={{ color: '#ef5350', fontSize: '1.5em', marginRight: 0.5, verticalAlign: 'middle' }} />
                                </Tooltip>
                              ) : null}
                              <span style={{ verticalAlign: 'middle' }}>{n.titulo ? n.titulo.toUpperCase() : ''}</span>
                            </div>
                            {authActions.isEmpresa() && isWinner(n) ? <span style={{ backgroundColor: '#00cc66', borderRadius: 8, padding: 4, color: '#fff', fontSize: 12, marginRight: 4, fontWeight: 400 }}>Você é a empresa vencedora desta ordem de serviço</span> : null}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.orgao ? n.orgao.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <CircleIcon sx={{ color: getCor(n.ultimoEvento), fontSize: 12, marginRight: 1 }} />
                              <span>
                                {getNomeUltimoEventoFormatadoLista(n.ultimoEvento, n)}
                              </span>
                              {n.ordensServicosEmpenhos && n.ordensServicosEmpenhos.length > 0 &&
                                <Tooltip title='Esta ordem de serviço possui um empenho cadastrado'>
                                  <div style={{ marginLeft: 2, padding: 3, alignItems: 'center', marginTop: 4 }}>
                                    <TurnedInNotIcon style={{ fontSize: '1.5em' }} />
                                  </div>
                                </Tooltip>
                              }
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            {authActions.isEmpresa() ?
                              <AccessControl
                                rule={'ordensServico.finalize'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Visualizar"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/ordemServico/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Visualizar'}>
                                      <Tooltip >
                                        <RemoveRedEyeIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              :
                              <AccessControl
                                rule={'ordensServico.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/ordemServico/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Editar'}>
                                      <Tooltip >
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            }
                            {n.ultimoEvento != STATUS_SERVICO.RECUSADO ?
                              <AccessControl
                                rule={'ordensServico.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleOpenModalMotivo(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              : null}
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      isLoading ?
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
        no={() => <AccessDenied />}
      />

      {
        (modalMotivo ?
          <OrdemServicoMotivoModal
            toggle={toggleModalMotivo}
            modal={modalMotivo}
            props={props}
            idOrdemServico={idOrdemServico}
          />
          :
          null
        )
      }
    </Page >
  )
}
OrdemServico.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedOrdemServicoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdemServico))
)
export { connectedOrdemServicoPage as OrdemServico }
