import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usuarioAction } from '../../_actions'

const EmailVerification = (props) => {
  const dispatch = useDispatch()
  const refreshed = useSelector((state) => state.authentication.refreshed)
  const loggedIn = useSelector((state) => state.authentication.loggedIn)

  const verificaEmail = () => {
    const query = new URLSearchParams(props.location.search)
    var email = query.toString().substr(6, query.toString().indexOf('&', 0) - 6)
    email = decodeURIComponent(email)
    var token = query
      .toString()
      .substr(
        query.toString().indexOf('token=', 0) + 6,
        query.toString().length - query.toString().indexOf('token=', 0) - 6
      )

    let payload = {
      login: decodeURIComponent(email),
      senha: 'senha'
    }
    dispatch(usuarioAction.emailVerification(payload, token))
  }

  useEffect(() => {
    //console.log('loggedIn', loggedIn)
    if (loggedIn) {
      if (refreshed) {
        verificaEmail()
      }
    }
  }, [refreshed])

  useEffect(() => {
    if (!loggedIn) {
      verificaEmail()
    }
  }, [])

  return <></>
}
export default EmailVerification
