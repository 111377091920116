import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { history } from '../utils'
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../components';
import clsx from 'clsx'
import { AccessControl } from '../components/Utils/AccessControl'

const useStyles = makeStyles(() => ({
  root: {}
}))

const TitleContent = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  useEffect(() => {
    console.log('props', props)
  }, [props])

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid item>
        <Typography component="h3" gutterBottom variant="overline">
          {props.subTitle}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>
          <IconButton
            aria-label="Ajuda"
            component="a"
            onClick={() =>
              handleOpenModalHelp()
            }
            size="small"
          >
            <Tooltip title={'Ajuda'}>
              <Tooltip>
                <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
              </Tooltip>
            </Tooltip>
          </IconButton>
        </div>
      </Grid>
      <AccessControl
        rule={props.rule} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid item>
            <Grid item xs={6} align="right">
              <Button
                variant="contained"
                style={{ backgroundColor: '#000' }}
                onClick={() => history.push(`${props.href}`)}
              >
                NOVO
              </Button>
            </Grid>
          </Grid>
        )}
      />
      <br></br>
      {props.length !== undefined && props.length !== 0 ? (
        <Grid item>
          <Typography variant="body2" align="left" gutterBottom>
            Total de dados encontrados: <b>{props.length}</b>
          </Typography>
        </Grid>
      ) : (
        <Typography variant="body2" align="left" gutterBottom>
          <b>{'Nenhum dado encontrado.'}</b>
        </Typography>
      )}
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={props.telaManual}
          />
          :
          null
        )
      }
    </div>
  )
}

TitleContent.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  rule: PropTypes.string,
  telaManual: PropTypes.string.isRequired
}
export default TitleContent
