import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  ordemServicoAction,
  ordemServicoOrcamentoAction
} from '../../_actions'
import { toast } from 'react-toastify';
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'

export const OrdemServicoMotivoModal = ({ modal, toggle, props, idOrdemServico, orcamento }) => {

  const formRef = React.createRef(null);

  const [motivo, setMotivo] = useState('');

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const handleClose = () => {
    toggle();
  }

  const ordemServico = useSelector((state) => state.ordemServico.ordemServico)

  useEffect(() => {
    if (idOrdemServico) {
      dispatch(ordemServicoAction.getOrdemServicoById(idOrdemServico))
    }
  }, [idOrdemServico])

  function isMenorPreco(valor) {

    var min = props.ordemServico?.ordensServicosOrcamentos?.reduce(function (prev, curr) {
      return prev.valor < curr.valor ? prev : curr;
    });

    if (min && min != 0 && min != '' && min.valor == valor) {
      return true;
    } else {
      return false;
    }
  }

  const handleSubmit = () => {
    // Verifica se o motivo é nulo, indefinido ou uma string vazia
    const motivoInvalido = motivo == null || motivo == undefined || motivo === '';
  
    // Verifica se o número de orçamentos é menor que 3
    const menosDeTresOrcamentos = props.ordemServico && props.ordemServico.ordensServicosOrcamentos && props.ordemServico.ordensServicosOrcamentos.length < 3;
  
    // Verifica se o orçamento selecionado não é o de menor preço
    const naoMenorPreco = !isMenorPreco(orcamento.valor);
  
    if (menosDeTresOrcamentos || naoMenorPreco) {
      if (motivoInvalido) {
        toast.error('É obrigatório o preenchimento de um motivo');
      } else {
        dispatch(ordemServicoOrcamentoAction.editOrdemServicoOrcamentoAprova(orcamento.id, props.ordemServico.id, motivo));
        toggle();
      }
    } else {
      dispatch(ordemServicoOrcamentoAction.editOrdemServicoOrcamentoAprova(orcamento.id, props.ordemServico.id, motivo));
      toggle();
    }
  };  

  function InsertText() {
    return (
      <Typography variant="h6">
        <b>{'Aprovar Orçamento'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Alterar o Status de um Orçamento"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <TextValidator
                className={classes.textField}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                id="motivo"
                label="Informe o Motivo"
                margin="normal"
                onInput={stringUtils.toInputUppercase}
                onChange={(event) => setMotivo(event.target.value)}
                value={motivo || ''}
                variant="outlined"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.ordemServicoOrcamento.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.ordemServicoOrcamento.isLoading && <CircularProgress size={24} />}
                >
                  {props.ordemServicoOrcamento.isLoading ? 'Aprovando orçamento...' : 'Aprovar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
