export const TIPO_USUARIO = {
  ADMIN: 1,
  GESTOR_ORGAO: 2,
  PRESTADOR: 3,
  MOTORISTA: 4,
  FISCAL: 5,
  USUARIO_ORGAO: 6
};

export const STATUS_SERVICO = {
  LANCAMENTO: null,
  PENDENTE: '1',
  RECEBENDO_ORCAMENTOS: '2',
  ORCAMENTO_APROVADO: '3',
  RECUSADO: '4',
  CONCLUIDO: '5',
  AVALIADO: '6',
  VERIFICADO: '7',
  DESERTO: '8',
  SEM_SUCESSO: '9'
};

export const STATUS_FINANCEIRO_EMPENHO = {
  EM_ABERTO: '1',
  PAGOS: '2',
  TODOS: '3'
};

export const MANUAIS_CATEGORIAS = {
  ABA_CADASTRO_ORDEM_SERVICO: 1,
  ABA_EDICAO_ORDEM_SERVICO: 2,
  LISTAGEM_ORDEM_SERVICO: 3,
  CADASTRO_ORGAO_ADMIN: 4,
  CADASTRO_ORGAO_ORGAO: 5,
  CADASTRO_EMPRESA_ADMIN: 6,
  CADASTRO_EMPRESA_EMPRESA: 7,
  EDICAO_ORGAO: 8,
  LISTAGEM_ORGAO: 9,
  CADASTRO_SUB_ORGAO: 10,
  EDICAO_SUB_ORGAO: 11,
  LISTAGEM_SUB_ORGAO: 12,
  EDICAO_PARAMETRO: 13,
  EDICAO_EMPRESA: 14,
  CADASTRO_USUARIO: 15,
  EDICAO_USUARIO: 16,
  LISTAGEM_USUARIO: 17,
  CADASTRO_VEICULO: 18,
  EDICAO_VEICULO: 19,
  LISTAGEM_VEICULO: 20,
  LISTAGEM_ORCAMENTO: 21,
  CADASTRO_ORCAMENTO: 22,
  LISTAGEM_EMPENHO: 23,
  CADASTRO_EMPENHO: 24,
  RELATORIO_EMPRESA_ORGAO: 25,
  RELATORIO_SERVICO_REALIZADO: 26,
  CADASTRO_AJUDA: 27,
  EDICAO_AJUDA: 28,
  LISTAGEM_AJUDA: 29,
  ABA_ORDEM_SERVICO_FINANCEIRO: 30,
  ABA_ORDEM_SERVICO_OCORRENCIA: 31,
  ABA_ORDEM_SERVICO_ORCAMENTO: 32,
  DASHBOARD: 33,
  LISTAGEM_EMPRESA: 34,
  EMPRESA_ABA_ANEXO: 35,
  LISTAGEM_MARCA: 36,
  LISTAGEM_PARAMETRO: 37,
  LISTAGEM_COBRANCA: 38,
  RELATORIO_LOGS_SISTEMA: 39,
  CADASTRO_MARCA: 40,
  LISTAGEM_NOTIFICACAO: 41,
  CADASTRO_NOTIFICACAO: 42,
  LISTAGEM_ORDEM_AGUARDANDO_ORCAMENTO: 43,
  LISTAGEM_ORDEM_ORCAMENTO_ENVIADO: 44
};
